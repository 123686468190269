<template>
  <div class="filter" :key="filterkey">
    <div class="filterBox">
      <div class="qiebox" style="display:none;" v-show="showFilter.length > 1">
        <div class="qieboxin">
          <span @click="andSwitch" v-text="andText == 'and' ? '且' : '或'"></span>
        </div>
      </div>
      <div class="filtList" :style="{ 'margin-left': showFilter.length > 1 ? '' : '-24px' }" :key="showFilterkey">
        <div class="rowitem" v-show="showFilter.length > 0" v-for="(row, rowidx) in showFilter" :key="rowidx">
          <div class="colitem">
            <el-popover popper-class="fields" placement="bottom-start" width="660" trigger="click" v-model="row.popVisible" :key="filterkey">
              <filterPopper :groupdata="groupData" @change-filtermame="changeFilterName" :rowindex="rowidx" :filterTemp="showFilter" :operator="operator" />
              <el-button class="groupshowname" placeholder="请选择" size="small" slot="reference"
                >{{ row.showname
                }}<b v-if="row.hassame" :class="row.category == 'event' ? 'evtattr' : 'etyattr'" v-text="row.category == 'event' ? '事件' : '实体'"></b
                ><i class="el-icon-caret-bottom"></i
              ></el-button>
            </el-popover>
          </div>
          <extFilter :row="row" :rowidx="rowidx" :eventids="initeventid()" @changeitem="changeitem" />

          <span class="filt-unit" v-if="row.unit">{{ row.unit }}</span>
          <i class="el-icon-close" @click="delFilterColum(rowidx)"></i>
        </div>
      </div>
    </div>
    <el-button v-if="showFilter.length < 5" class="addbtn" type="text" @click="addFilterRow" size="small"><i class="plusicon">+</i>添加条件</el-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import filterPopper from './filterPopper'
import comJs from './com'
import { bi_extFilter as extFilter } from 'bi_common'
import InitUserly from '@/plugin/userly.js'

export default {
  name: 'headfilter',
  props: ['paramfilter', 'eventlist'],
  data() {
    return {
      copyFilterModel: null,
      groupData: [],
      showFilter: [],
      copyMetadata: null,
      geolocations: [], //商场
      filterkey: 0,
      showFilterkey: 0,
      andText: '',
    }
  },
  components: {
    filterPopper,
    extFilter,
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
      operator: state => state.operator,
    }),
  },
  watch: {},
  async created() {
    this.geolocations = this.basicInfo.geolocations
    this.groupData = comJs.getgroupData(this, this.paramfilter)
    this.init()
  },
  methods: {
    andSwitch() {
      if (this.andText == 'and') {
        this.andText = 'or'
        this.copyFilterModel.operator = 'or'
      } else {
        this.andText = 'and'
        this.copyFilterModel.operator = 'and'
      }
      this.filterkey++
    },
    init() {
      let filter = this.paramfilter.filter || {
        conditions: [],
        operator: 'and',
      }
      this.copyFilterModel = JSON.parse(JSON.stringify(filter))
      const conditions = JSON.parse(JSON.stringify(filter.conditions))
      this.andText = filter.operator
      this.showFilter = this.initFilt(conditions)
    },
    initFilt(conditions) {
      let d = JSON.parse(JSON.stringify(this.groupData))
      let showFilter = []
      conditions.forEach((x, idx) => {
        d.forEach(g => {
          g.child.forEach(c => {
            let properties = JSON.parse(JSON.stringify(c.properties))
            let l = properties.filter(p => {
              return p.code == x.code && (!x.eventID || g.id == x.eventID) && (!x.category || x.category == p.category)
            })[0]
            if (l) {
              let ope = this.operator.filter(k => {
                return k.dataTypes.includes(l.dataType)
              })[0].modes
              let operator = []
              if (l.dataType == 'Date') {
                let mod = ope.filter(k => {
                  return k.modeCode == x.mode || k.modeCode == x.modeCode
                })
                operator = mod[0] ? mod[0].operators : []
                l.dataOperators = ope
                l.operators = operator
              } else {
                operator = ope[0].operators
                l.operators = operator
              }

              if (l.dataType == 'Geo') {
                let geo = this.geolocations.find(g => {
                  return g.latitude == x.values[0] && g.longitude == x.values[1]
                })
                this.$set(l, 'geoValue', geo.id)

                x.values.splice(0, 2)
              }

              l.mode = x.mode
              this.$set(l, 'modeCode', x.mode)
              l.operator = x.operator
              let curoperator = operator.find(op => {
                return op.code == x.operator
              })
              l.canAutoComplete = curoperator.canAutoComplete
              l.canMultiple = curoperator.canMultiple
              l.quantity = curoperator.quantity

              let vals = []
              if (l.operator == 'day_inrange') {
                x.values.forEach(va => {
                  let list1 = typeof va == 'string' ? [va.substring(0, 2), va.substring(2, 4)] : []
                  vals.push(list1)
                })
              } else {
                vals = x.values
              }
              this.$set(l, 'values', vals)
              // l.tags = x.tags
              l.tags =
                x.tagGroups ||
                (x.tags &&
                  x.tags.map(t => {
                    return {
                      mode: 'current',
                      ...t,
                    }
                  }))
              // l.eventID = g.id ? g.id : null
              l.eventID = null
              l.showname = g.id ? g.groupName + '的' + l.name : l.name
              l.popVisible = false
              if (this.isSameGroup(l, d)) {
                l.hassame = true
              }
              showFilter.push(l)
            }
          })
        })
      })
      return showFilter
    },
    addFilterRow() {
      let d = JSON.parse(JSON.stringify(this.groupData))
      let first = (d[0] && d[0].child[0] && d[0].child[0].properties && d[0].child[0].properties[0]) || null
      if (first) {
        let ope = this.operator.filter(x => {
          return x.dataTypes.includes(first.dataType)
        })[0].modes

        let operator = JSON.parse(JSON.stringify(ope[0].operators))
        if (first.dataType == 'Date') {
          first.dataOperators = ope
          first.operators = operator
        } else {
          first.operators = operator
        }

        if (first.dataType == 'Geo') {
          this.$set(first, 'geoValue', this.geolocations[0].id)
        }

        this.$set(first, 'modeCode', ope[0].modeCode)
        this.$set(first, 'operator', operator[0].code)
        this.$set(first, 'canAutoComplete', operator[0].canAutoComplete)
        this.$set(first, 'canMultiple', operator[0].canMultiple)
        this.$set(first, 'quantity', operator[0].quantity)
        this.$set(first, 'values', [])
        this.$set(first, 'tags', [])
        let event = d[0]
        // first.eventID = event.id ? event.id : null
        first.eventID = null
        first.showname = event.id ? event.groupName + '的' + first.name : first.name
        if (this.isSameGroup(first, d)) {
          first.hassame = true
        }
        this.showFilter.push(first)
        this.filterkey++
      }
    },
    //名称修改
    changeFilterName(item) {
      this.showFilter = item
      this.showFilterkey++
    },
    initeventid() {
      const ids = (this.eventlist && (this.paramfilter?.eventIds ?? null)) || null
      return ids
    },
    changeitem(i, item) {
      this.showFilter[i] = item
    },
    delFilterColum(idx) {
      this.showFilter.splice(idx, 1)
      this.filterkey++
    },
    exportfilter() {
      let conditions = []
      let self = this
      let copyshowFilter = JSON.parse(JSON.stringify(self.showFilter))
      if (self.showFilter) {
        conditions = copyshowFilter.map(x => {
          if (x.operator == 'day_inrange') {
            let day_val = x.values.map(v => {
              let value = v[0] + '' + v[1]
              return value
            })
            x.values = day_val
          }
          let vals = x.values && x.values.filter(x => x !== 'undefined' && x !== null && x !== '')
          let values =
            vals &&
            vals.map(v => {
              return typeof v == 'number' ? v : v.trim()
            })
          if (x.dataType == 'Geo') {
            let geo = this.geolocations.find(g => {
              return g.id == x.geoValue
            })
            let tudo = [geo.latitude, geo.longitude]
            values = tudo.concat(values)
          }
          let copyTags = []
          if (x.tags) {
            copyTags = JSON.parse(JSON.stringify(x.tags))
            copyTags = copyTags.map(tg => {
              delete tg.visible
              if (tg.mode === 'current') {
                delete tg.preset
              }
              return tg
            })
          }
          return {
            // eventID: x.eventID ? x.eventID : null,
            eventID: null,
            code: x.code,
            mode: x.modeCode,
            operator: x.operator,
            dataType: x.dataType,
            category: x.category,
            tags: copyTags,
            values: values,
            quantity: x.operators.filter(o => o.code == x.operator)[0].quantity,
          }
        })
      }
      conditions = conditions.filter(x => {
        if (x.quantity == 0) {
          return true
        }
        if (x.dataType == 'Tag') return x.tags.length >= x.quantity
        if (x.dataType == 'Geo') return x.values.length >= 2 + x.quantity
        return x.values.length >= x.quantity
      })

      let filter = null
      if (conditions.length > 0) {
        filter = {
          operator: self.copyFilterModel.operator,
          conditions: conditions,
        }
      }
      return filter
    },
    isSameGroup(item, d) {
      let samename = true
      let curevt = []
      d.forEach(g => {
        if (g.child[0].properties && g.child.length > 1) {
          g.child.forEach(c => {
            const cur = c.properties.find(p => {
              return p.code == item.code && p.name == item.name
            })
            if (cur) {
              curevt = g.child
            }
          })
        }
      })
      curevt.forEach(c => {
        const cur = c.properties.find(p => {
          return p.name == item.name
        })
        if (!cur) {
          samename = false
        }
      })
      return curevt.length > 0 ? samename : false
    },
  },
  mounted() {
    const user = this.basicInfo && this.basicInfo.user
    if (user) {
      InitUserly({ mallId: '', visitorId: user.id, userName: user.name, userType: user.identityType, mallName: '' })
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/style/pages/filterCom.scss';
</style>
