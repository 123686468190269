function setUserlySDK() {
  ;(function(e, t, n) {
    var l = 'userly-client-init'
    if (null === t.getElementById(l)) {
      var a = t.getElementsByTagName(n)[0],
        i = t.createElement(n)
      ;(i.async = 1),
        (i.defer = 1),
        (i.src = '//app.userly.cn/assets/InitializationSDK.bundle.js?t='.concat(new Date().getTime())),
        (i.id = l),
        (i.onload = function() {
          if (null != e && e.userlyAPI) {
            e.userlyAPILoaded = !0
            var n = null
            t.createEvent
              ? (n = t.createEvent('Event')).initEvent('Userly.UserClient.onLoad', !0, !0)
              : t.createEventObject && ((n = t.createEventObject()).eventType = eventName),
              t.dispatchEvent && n ? t.dispatchEvent(n) : t.fireEvent && n && t.fireEvent('Userly.UserClient.onLoad', n)
          } else {
            var l, a
            null == e || null === (l = e.console) || void 0 === l || null === (a = l.log) || void 0 === a || a.call(l, 'Userly SDK Fail to load')
          }
        }),
        a.parentNode.insertBefore(i, a)
    }
  })(window, document, 'script')
}
function setUserlyHandle(prop) {
  ;(function(i) {
    if (i !== null && i !== void 0 && i.userlyAPIInited) {
      return
    }
    if (i !== null && i !== void 0 && i.userlyAPILoaded) {
      n()
    } else if (i !== null && i !== void 0 && i.addEventListener) {
      i.addEventListener('Userly.UserClient.onLoad', n)
    }
    function n() {
      var n
      if (i !== null && i !== void 0 && (n = i.userlyAPI) !== null && n !== void 0 && n.init) {
        i.userlyAPI.init(prop)
        i.userlyAPIInited = true
      }
    }
  })(window)
}
function InitUserly({ mallId, visitorId, userName, userType, mallName }) {
  setUserlySDK()
  let init = endTime => {
    const usertypeobj = {
      1: '内部用户',
      2: '单商场用户',
      3: '单商户用户',
      4: '多商场用户',
      5: '连锁商户用户',
      6: '运营用户',
      7: '供应商',
      8: '集团用户',
    }
    userType = userType ? usertypeobj[userType] : ''
    let wbEndTime = endTime || ''
    let wbStatus = ''
    if (endTime) {
      var diff = (new Date(endTime).getTime() - Date.now()) / 1000 / (24 * 3600)
      if (diff < 0) {
        wbStatus = '已到期'
      } else if (diff > 15) {
        wbStatus = '未到期'
      } else {
        wbStatus = '即将到期'
      }
    }
    let prop = {
      accountId: '1579454873914953730',
      token: '+Hm/miDbwNl+vwxuYJwPVA==',
      visitorId, // 用户id
      userType, // 用户类型
      mallName, // 商场名称
      mallId, // 商场id
      userName, // 用户名
      wbEndTime, // 维保到期时间
      wbStatus, // 维保到期状态
    }
    setUserlyHandle(prop)
  }
  let post = option => {
    let param = option.data
    let xhr = new XMLHttpRequest()
    xhr.open('post', option.url, option.async)
    xhr.withCredentials = true
    xhr.setRequestHeader('content-type', 'application/json;charset=utf-8')
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let res = JSON.parse(xhr.responseText)
        option.success(res)
      } else {
        option.error()
      }
    }
    xhr.send(param)
  }
  if (mallId) {
    let data = { mallId: mallId }
    const _origin = location.origin
    const hostapi = _origin.includes('mp-t') ? 'https://mp-t.mallcoo.cn/' : 'https://mp.mallcoo.cn/'
    post({
      url: hostapi + 'api/pms/maint/getMallMaintInfo',
      crossDomain: true,
      data: JSON.stringify(data),
      success: function(res) {
        if (res.m == 1 && res.d) {
          let wbEndTime = res.d?.data?.endTime || ''
          init(wbEndTime)
        } else {
          init()
        }
      },
      error: function() {
        init()
      },
    })
  } else {
    init()
  }
}

export default InitUserly
