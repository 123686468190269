<template>
  <div class="dashboardList" :key="dashkey">
    <div v-if="cardsRenderComplete" class="cards-render-complete"></div>
    <gridHead :key="gridheadkey" :dashboard="dashboard" @changealltime="changeAlltime" @mallchange="mallChange" @confirmInjection="confirmInjection" />
    <div class="gridList" v-if="gridData.length > 0">
      <grid-layout
        :layout.sync="gridData"
        :responsive="true"
        :breakpoints="{ lg: 1100, md: 996, sm: 768, xs: 480, xxs: 0 }"
        :col-num="12"
        :row-height="40"
        :is-draggable="system == 'bi'"
        :is-resizable="system == 'bi'"
        :vertical-compact="true"
        :margin="[15, 15]"
        :use-css-transforms="true"
        @layout-created="layoutCreatedEvent"
        @layout-updated="layoutUpdatedEvent"
        @layout-ready="layoutReadyEvent"
      >
        <grid-item
          v-for="item in gridData"
          v-loading="chartData[item.i].loading"
          :element-loading-spinner="'cardloading loading' + basicInfo.project.mainColor"
          element-loading-background="rgba(0, 0, 0, 0)"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :minW="chartData[item.i].searchCode === 'combination' ? 4 : 2"
          :minH="chartData[item.i].searchCode === 'combination' ? 4 : 1"
          @resize="resizeEvent"
          @resized="resizedEvent"
          @moved="movedEvent"
          drag-allow-from=".gridtitle"
          drag-ignore-from=".charts-box"
          :class="{ full: fullchartidx == item.i }"
        >
          <div :ref="'gridItemref' + item.i" class="grid-item-box" @mouseleave="mouseleaveGridItem" @click="clickGridItem">
            <div class="signFull">
              <h2>{{ chartData[item.i].reqObj.title || chartData[item.i].reqObj[0].displayTitle }}</h2>
              <span @click="signFull(item.i)">退出全屏</span>
            </div>
            <cardSet
              v-if="chartData[item.i].canVisit"
              :item="item"
              :bookmark="bookmarks.find(book => book.id == chartData[item.i].id)"
              :bookmarks="bookmarks"
              :chartd="chartData[item.i]"
              :chartsetidx="chartsetidx"
              @chartsetidxChange="chartsetidxChange"
              @startFull="startFull"
              @searchchange="realtime => searchchange(item.i, chartData[item.i].reqObj, '', 'refresh', realtime)"
              @createCopy="newbookmark => createCopy(item.i, newbookmark)"
              @delbookMark="deltype => delbookMark(deltype, item.i)"
              @emitDownloadExcel="emitchart => emitDownloadExcel(emitchart, item.i)"
              @drillSubmit="drilldata => drillSubmit(drilldata, item.i)"
              @linkageSubmit="oldlinkageset => linkageSubmit(oldlinkageset, item.i)"
            />
            <gridBar
              :item="item"
              :bookmark="bookmarks.find(book => book.id == chartData[item.i].id)"
              :chartd="chartData[item.i]"
              :chartsetidx="chartsetidx"
              :prefilter="chartData[item.i].prefilter"
              @timechange="timeitem => timechange(timeitem.timeidx, timeitem.time, chartData[item.i], item.i)"
              @searchchange="setitem => searchchange(item.i, chartData[item.i].reqObj, setitem.contrast, setitem.type)"
            />
            <div
              v-if="chartData[item.i] && chartData[item.i].searchCode === 'combination'"
              class="charts-box"
              :style="{ height: fullchartidx == item.i ? 'calc(100% - 70px)' : chartData[item.i].timeRange ? 'calc(100% - 65px)' : 'calc(100% - 40px)' }"
              :key="tablefullkey"
            >
              <template v-if="!chartData[item.i].canVisit">
                <div class="nodata dis_flex_center" :style="{ height: chartData[item.i].prefilter ? 'calc(100% - 20px)' : '100%' }">
                  <div class="text_center">
                    <img src="~@/assets/images/nodata/canVisit.png" alt />
                    <span>暂无卡片数据权限</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="carddashboard" style="height: 100%">
                  <carddashboard
                    :ref="'carddashboard' + item.i"
                    :chartDataItem="chartData[item.i]"
                    :griddata="chartData[item.i].reqObj.cardGrid"
                    :cardlist="chartData[item.i].reqObj.cardlist"
                    :griditem="item"
                  ></carddashboard>
                </div>
              </template>
            </div>

            <div
              class="charts-box"
              v-if="chartData[item.i] && chartData[item.i].searchCode !== 'combination'"
              :style="{ height: fullchartidx == item.i ? 'calc(100% - 70px)' : chartData[item.i].timeRange ? 'calc(100% - 65px)' : 'calc(100% - 40px)' }"
              :key="tablefullkey"
            >
              <template v-if="!chartData[item.i].canVisit">
                <div class="nodata dis_flex_center" :style="{ height: chartData[item.i].prefilter ? 'calc(100% - 20px)' : '100%' }">
                  <div class="text_center">
                    <img src="~@/assets/images/nodata/canVisit.png" alt />
                    <span>暂无卡片数据权限</span>
                  </div>
                </div>
              </template>
              <template v-else-if="chartData[item.i].option && chartData[item.i].option.noData">
                <div class="nodata dis_flex_center" :style="{ height: chartData[item.i].prefilter ? 'calc(100% - 20px)' : '100%' }">
                  <div>
                    <img src="~@/assets/images/nodata-bg.png" alt />
                    <span>暂无数据</span>
                  </div>
                </div>
              </template>
              <template v-else-if="chartData[item.i] && chartData[item.i].option">
                <template v-if="chartData[item.i].cardinfos && chartData[item.i].version == '2.0'">
                  <vue-bi-echarts
                    v-if="chartData[item.i].cardinfos && gridready"
                    :ref="'chart' + item.i"
                    :key="'bicardkey' + item.i"
                    :cardinfos="initcardinfo(item.i, chartData[item.i].cardinfos)"
                    :cardposition="item"
                    :rowheight="
                      fullchartidx == item.i ? 0 : chartData[item.i].timeRange ? item.h * 40 + (item.h - 1) * 15 - 91 : item.h * 40 + (item.h - 1) * 15 - 68
                    "
                    :style="{
                      height:
                        fullchartidx == item.i || !['table', 'table_hotmap', 'table_path'].includes(chartData[item.i].reqObj.mode)
                          ? chartData[item.i].prefilter
                            ? 'calc(100% - 20px) !important'
                            : '100%'
                          : chartData[item.i].option && chartData[item.i].option.opts && chartData[item.i].option.opts.enablePivot == 'full'
                          ? 'calc(100% - 100px)'
                          : (chartData[item.i].timeRange
                              ? chartData[item.i].prefilter
                                ? item.h * 40 + (item.h - 1) * 15 - 111
                                : item.h * 40 + (item.h - 1) * 15 - 91
                              : chartData[item.i].prefilter
                              ? item.h * 40 + (item.h - 1) * 15 - 88
                              : item.h * 40 + (item.h - 1) * 15 - 68) + 'px',
                    }"
                    :class="{ enabledrill: bookmarks[item.i] && bookmarks[item.i].drillSetting && bookmarks[item.i].drillSetting.isEnabled }"
                    @changesort="td => changesort(td, item.i)"
                    @drillclick="clickobj => drillclick(clickobj, item.i, -2)"
                  ></vue-bi-echarts>
                </template>
                <template v-else>
                  <div
                    class="chart-table funnel-table"
                    v-if="
                      chartData[item.i].option &&
                        ((chartData[item.i].reqObj.mode == 'funnel' &&
                          chartData[item.i].reqObj.showType &&
                          chartData[item.i].reqObj.showType.code == 'table') ||
                          chartData[item.i].reqObj.mode == 'table_more')
                    "
                    :ref="'charttable' + item.i"
                    :key="'funnel' + item.i"
                  >
                    <funneltable
                      :ref="'eltable' + item.i"
                      :chartdata.sync="chartData[item.i]"
                      :rowheight="
                        fullchartidx == item.i ? 0 : chartData[item.i].timeRange ? item.h * 40 + (item.h - 1) * 15 - 101 : item.h * 40 + (item.h - 1) * 15 - 82
                      "
                    />
                  </div>
                  <div
                    class="chart-table common-table"
                    v-else-if="chartData[item.i].option && chartData[item.i].reqObj.mode == 'table' && chartData[item.i].searchCode == 'segment22'"
                    :ref="'charttable' + item.i"
                  >
                    <div
                      style="width: 100%"
                      :style="{
                        height:
                          fullchartidx == item.i
                            ? '100%'
                            : (chartData[item.i].option.data.setting && chartData[item.i].option.data.setting.enablePivot == 'full'
                                ? 'none'
                                : chartData[item.i].reqObj.timeRange
                                ? item.h * 40 + (item.h - 1) * 15 - 105
                                : item.h * 40 + (item.h - 1) * 15 - 65) + 'px',
                      }"
                    >
                      <pl-table
                        :datas="chartData[item.i].option.data.opt"
                        big-data-checkbox
                        ref="plTable"
                        use-virtual
                        size="small"
                        :auto-resize="true"
                        :row-height="32"
                        :pagination-show="false"
                      >
                        <!-- <pl-table-column type="index" fixed/> -->
                        <!--show-overflow-tooltip属性代表超出则内容部分给出提示框-->
                        <pl-table-column
                          v-for="(t, index) in chartData[item.i].option.data.tableHead"
                          :key="index"
                          :show-overflow-tooltip="true"
                          :prop="t.key"
                          :label="t.label"
                          :render-header="labelHead"
                          sortable
                        >
                          <template slot-scope="scope">
                            <div
                              v-if="t.key.includes('loop') || (scope.row[t.realkey] && scope.row[t.realkey].includes('loop'))"
                              :style="{ color: scope.row[t.key] > 0 ? '#45BB44' : scope.row[t.key] < 0 ? '#FF6054' : '' }"
                              v-text="(scope.row[t.key] * 100).toFixed(2) + '%' != '0.00%' ? (scope.row[t.key] * 100).toFixed(2) + '%' : '-'"
                            ></div>
                            <div
                              v-else-if="scope.row[t.realkey] && scope.row[t.realkey].includes('_rate')"
                              :style="{ color: scope.row[t.key].indexOf('-') == -1 ? '#45BB44' : scope.row[t.key] != '0.00%' ? '#FF6054' : '' }"
                              v-text="scope.row[t.key] != '0.00%' ? scope.row[t.key] : '-'"
                            ></div>
                            <div
                              v-else-if="
                                (t.key.includes('zhanbi') || (scope.row[t.realkey] && scope.row[t.realkey].includes('zhanbi'))) && scope.row[t.key] != '-'
                              "
                            >
                              {{ (scope.row[t.key] * 100).toFixed(2) }}%
                            </div>
                            <div
                              class="canclick"
                              v-else-if="
                                (t.key.includes('distinct_totalcount') || (scope.row[t.realkey] && scope.row[t.realkey].includes('distinct_totalcount'))) &&
                                  !scope.row.average &&
                                  typeof scope.row[t.key] == 'number'
                              "
                            >
                              <a :href="getcounturl()" :style="cardMainColor(chartData[item.i])">{{ scope.row[t.key] }}</a>
                            </div>
                            <div v-else>{{ scope.row[t.key] }}</div>
                          </template>
                        </pl-table-column>
                      </pl-table>
                    </div>
                  </div>
                  <div
                    class="chart-table common-table"
                    v-else-if="chartData[item.i].option && ['table', 'table_hotmap', 'table_path'].includes(chartData[item.i].reqObj.mode)"
                    :ref="'charttable' + item.i"
                  >
                    <my-table
                      :ref="'eltable' + item.i"
                      :id="'eltable' + item.i"
                      :col="chartData[item.i].option.thead"
                      :data="chartData[item.i].option.tbody"
                      :opts="chartData[item.i].option.opts"
                      :chartoption="chartData[item.i]"
                      :resizedidx="chartData[item.i].resizedidx"
                      :rowheight="
                        fullchartidx == item.i ? 0 : chartData[item.i].timeRange ? item.h * 40 + (item.h - 1) * 15 - 101 : item.h * 40 + (item.h - 1) * 15 - 82
                      "
                      :style="{
                        height:
                          fullchartidx == item.i
                            ? '100%'
                            : chartData[item.i].option && chartData[item.i].option.opts && chartData[item.i].option.opts.enablePivot == 'full'
                            ? 'calc(100% - 100px)'
                            : (chartData[item.i].timeRange ? item.h * 40 + (item.h - 1) * 15 - 91 : item.h * 40 + (item.h - 1) * 15 - 72) + 'px',
                      }"
                      @changesort="td => changesort(td, item.i)"
                    ></my-table>
                  </div>
                  <div
                    class="cardbox dis_flex_center"
                    v-else-if="chartData[item.i].option && chartData[item.i].reqObj.mode == 'card' && chartData[item.i].curcardlabel"
                    :ref="'chartcard' + item.i"
                    :class="{ nolimit: chartData[item.i].option.numberFontSize == 'auto' }"
                  >
                    <div
                      v-if="
                        chartData[item.i].reqObj.parameter && chartData[item.i].reqObj.parameter.groups && chartData[item.i].reqObj.parameter.groups.length > 0
                      "
                      class="card-select"
                    >
                      <el-select v-model="chartData[item.i].curcardlabel" size="mini" @change="lab => cardchange(item.i, lab)">
                        <el-option v-for="item in chartData[item.i].option.arr" :key="item.label" :label="item.label" :value="item.label"></el-option>
                      </el-select>
                    </div>
                    <div class="cardinner" :class="'carditem' + item.w">
                      <div
                        class="cardmeasure"
                        v-if="chartData[item.i].option.showMeasure"
                        :style="{ 'font-size': chartData[item.i].option.measureNameFontSize }"
                      >
                        {{ chartData[item.i].option.measureName }}
                      </div>
                      <div class="cardnum" :style="cardMainColor(chartData[item.i])">
                        <span>{{ chartData[item.i].curcard.value }}</span>
                        <b
                          :style="{
                            'font-size':
                              parseInt(chartData[item.i].option.numberFontSize) > 36 ? parseInt(chartData[item.i].option.numberFontSize) / 3 + 'px' : '12px',
                          }"
                          >{{ chartData[item.i].option.numberunit }}{{ chartData[item.i].option.unitName }}</b
                        >
                      </div>
                      <div class="card-contrast dis_flex_center">
                        <div
                          class="card-contrast-item"
                          v-show="chartData[item.i].curcard.cardConrastdata.length > 0"
                          v-for="(carditem, cardidx) in chartData[item.i].curcard.cardConrastdata"
                          :key="cardidx"
                        >
                          <label v-text="carditem.title"></label>
                          <span
                            :class="{
                              'arrow-down': carditem.value.includes('-'),
                              'arrow-up': !carditem.value.includes('-'),
                              red_green: basicInfo.project.contrastTrendColor && basicInfo.project.contrastTrendColor == 1,
                            }"
                          >
                            <b v-text="carditem.value"></b>
                            <i :class="carditem.value.includes('-') ? 'el-icon-bottom' : 'el-icon-top'"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mapbox" v-else-if="chartData[item.i].option && chartData[item.i].reqObj.mode == 'map'" style="height: 100%">
                    <BIMap :ref="'chartmap' + item.i" :map-config="chartData[item.i].option.mapConfig" :idx="item.i" :key="chartData[item.i].mapkey"></BIMap>
                  </div>

                  <echartItem
                    v-else-if="chartData[item.i].option && gridready"
                    :ref="'chart' + item.i"
                    :resdata="chartData[item.i]"
                    :parent="parentset"
                  ></echartItem>
                </template>
              </template>
              <!-- 钻取tab -->
              <div
                v-if="
                  chartData[item.i] &&
                    chartData[item.i].prefilter &&
                    bookmarks[item.i] &&
                    bookmarks[item.i].drillSetting &&
                    bookmarks[item.i].drillSetting.isEnabled
                "
                class="drilltabs"
              >
                <div class="drilltab-item">
                  <span class="canclick" @click="drillclick('', item.i, -1)" v-text="initgroupname(item.i, -1)"></span>
                </div>
                <div class="drilltab-item" v-for="(dri, driidx) in chartData[item.i].prefilter" :key="driidx">
                  <template v-if="chartData[item.i].prefilter.length > driidx">
                    <b>></b
                    ><span
                      class="canclick"
                      v-if="chartData[item.i].prefilter.length > 1 && driidx < chartData[item.i].prefilter.length - 1"
                      @click="drillclick('', item.i, driidx)"
                      v-text="initgroupname(item.i, driidx)"
                    ></span>
                    <span v-if="driidx == chartData[item.i].prefilter.length - 1" v-text="initgroupname(item.i, driidx)"></span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import Scheduler from '@/script/global/Scheduler.js'
import gridHead from './gridHead'
import cardSet from './components/cardSet'
import gridBar from './components/gridBar'

import funneltable from './funneltable'
import VueGridLayout from '@/script/npmjs/vue-grid-layout.common.js'
import echartItem from '@/components/echartItem'
import carddashboard from './carddashboard/carddashboard'
import { getBookmarks, getDashboard, updateBookmarkPosition, removeBookmark, deleteBookmark, getDashboardBookmark } from '@/api/apiV2_dashboard'
import { getBookmark } from '@/api/reportAPI.js'
import { behaviorLoad, funnelLoad } from '@/api/apiV1'

import BIMap from '@/components/Map/Map'

import MyTable from '@/components/tables/MyTable'
import BI_config from '@/utils/config'

import { downloadExcel } from './downExcel'
import dashbdjs from './dashboardjs'
import dashbdjs2 from './dashboardjs2'
import linkageDrillJS from './js/linkagedrill'
import { Behavior } from '@/script/bookmarkV1/Behavior'
import { Behavior1 } from '@/script/bookmarkV1/Behavior1'

import { Retention } from '@/script/bookmarkV1/Retention'
import { Funnel } from '@/script/bookmarkV1/Funnel'
import { Association } from '@/script/bookmarkV1/Association'

import { allColor } from '@/datajs/static.js'
import cardinitMark from '@/views/dashboard/carddashboard/cardinitMark'
export default {
  props: ['sdata', 'addDashboard', 'checkmobile'],
  data() {
    return {
      name: 'dashboard',
      allColor: null,
      position: [],
      chartdatacache: [],
      gridheadkey: 0,
      dashkey: 0,

      gridData: [], //位置
      copygridData: [], //copy位置
      chartData: [],
      dashboard: '',
      bookmarks: [],
      funnelData: null,
      fullchartidx: -1,
      rdMainColor: 0,
      rdChartColor: 0,
      currentColor: [],
      currentSetBook: '',
      sorttable: [],

      parentset: {}, //父组件操作

      alltime: '',
      slotshowarr: ['hour', 'day', 'week', 'month', 'quarter'],

      changeColor: false,

      tablefullkey: 0,
      chartsetidx: -1,

      copyChartData: [],

      mapData: '',
      debounce: _.debounce(this.getData, 500),

      resizetimer: '',
      gridready: false,
      cardsRenderComplete: false,
    }
  },
  computed: {
    ...mapState({
      targets: state => state.targets,
      basicInfo: state => state.basicInfo,
      pageunit: state => state.pageunit,
      envdata: state => state.envdata,
      dashboardHash: state => state.dashboardHash,
      dashboards: state => state.dashboards,
      projectID: state => state.projectID,
      system: state => state.system,
      festivals: state => state.festivals,
      weathers: state => state.weathers,
      projectMalls: state => state.projectMalls,
    }),
  },
  components: {
    gridHead,
    cardSet,
    gridBar,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    echartItem,
    MyTable,
    BIMap,
    funneltable,
    carddashboard,
  },
  watch: {
    // 监控左侧菜单开关 重绘图表
    sdata: function() {
      this.chartData.forEach((x, idx) => {
        this.resizeEvent(idx, '', true)
      })
    },
    'basicInfo.project.theme': function() {
      this.$basicInfo = this.basicInfo
      this.themeReset()
    },
    'basicInfo.project.mainColor': function() {
      this.$basicInfo = this.basicInfo
      this.themeReset()
    },
    'basicInfo.project.axisUnit': function() {
      this.$basicInfo = this.basicInfo
      this.themeReset()
    },
    '$route.hash': function() {
      this.position = []
      this.chartdatacache = []
      this.debounce()
      this.gridheadkey++
    },
    'targets.length': function() {
      this.$targets = this.targets
      this.$envdata = this.envdata
    },
    'dashboards.length': function() {
      this.debounce()
    },

    rdMainColor: function() {
      this.getCurrentColor()
    },
    addDashboard: function() {
      this.chartdatacache = []
      this.debounce()
    },
    pageunit: function() {
      this.$pageunit = this.pageunit
    },
  },
  methods: {
    async getData() {
      this.$store.commit('setFullPath', this.$route.fullPath)
      this.gridData = []
      this.chartData = []
      this.copyChartData = []
      this.position = []
      this.bookmarks = []
      let dashboardID = this.$route.query.dashboardID || this.$route.hash.replace('#/', '')
      if (!dashboardID) {
        return
      }
      let res = await getDashboard(dashboardID)
      this.dashboard = res.data || {}
      this.$loading({ lock: true, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' })
      try {
        let v = await getBookmarks(this.system, dashboardID)
        v.data = this.$commonJs.caseToggle(v.data)
        await this.waitWeatherAndFestivalsRequest(v.data)
        let detail = { dashboard: this.dashboard }
        detail.dashboard.bookmarks = v.data
        this.$loading({ lock: false }).close()
        if (!v.error) {
          if (this.isHasV1(v.data)) {
            let param = {
              projectID: this.projectID,
            }
            //获取 1.0 指标
            const loaddata = await behaviorLoad(param, {})
            const funneldata = await funnelLoad(param, {})

            this.$store.commit('setEnvdata', funneldata)
            const targets = loaddata.targets
            let settargets = {}
            if (targets && targets.length > 0) {
              let event = targets[1] && targets[1].events ? targets[0].events.concat(targets[1].events) : targets[0].events
              event.forEach(item => {
                settargets[item.code] = item.text
              })
              this.$store.commit('setTargets', settargets)
            }
          }

          this.bookmarks = v.data.map(x => {
            // 联动 初始新增 linkages：{groups:[],filters:[]}
            x.linkages = { groups: [], filters: [] }
            return x
          })
          const scheduler = new Scheduler(5, this.$route.fullPath)

          this.bookmarks.forEach((el, i) => {
            this.copyChartData.push({ idx: i })
            linkageDrillJS.initLinkage(this.bookmarks, el)

            // 如果卡片有钻取
            if (el && el.drillSetting) {
              const getarrs = el.drillSetting.bookmarkIDs.map(b => getBookmark(b))
              Promise.all([...getarrs])
                .then(values => {
                  values = values.filter((va, vai) => va.data || !el.dependentBookmarks[vai].canVisit)
                  el.drillBookmarks = values.map((val, valuesidx) => {
                    const finddepend = el.dependentBookmarks.find(depend => depend.bookmarkID == el.drillSetting.bookmarkIDs[valuesidx])
                    if (finddepend.canVisit) {
                      return {
                        id: el.drillSetting.bookmarkIDs[valuesidx],
                        canVisit: finddepend.canVisit,
                        ...val.data,
                      }
                    } else {
                      return {
                        id: el.drillSetting.bookmarkIDs[valuesidx],
                        nameCN: finddepend.name,
                        canVisit: finddepend.canVisit,
                      }
                    }
                  })
                })
                .catch(() => {})
            }
            dashbdjs.getChartData(this, BI_config, detail, el, i, scheduler)
          })
          scheduler.start()
        } else {
          this.$confirm(v.message, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
          })
        }
      } catch (err) {
        console.error(err)
        this.$loading({ lock: false }).close()
      }

      // 埋点
      this.$trackView({ system: this.system, module: '数据面板', pageName: this.dashboard.nameCN, path: this.$route.path })
    },

    //是否有1.0卡片
    isHasV1(bookmarks = []) {
      const v1 = bookmarks.find(x => !x.version || x.version == '1.0')
      return v1
    },

    async waitWeatherAndFestivalsRequest(bookmarks) {
      let self = this
      let waitWeatherRequest = async resolve => {
        if (self.weathers.loading) {
          setTimeout(async function() {
            await waitWeatherRequest(resolve)
          }, 50)
        } else {
          resolve()
        }
      }
      let waitFestivalsRequest = async resolve => {
        if (self.festivals.loading) {
          setTimeout(async function() {
            await waitFestivalsRequest(resolve)
          }, 50)
        } else {
          resolve()
        }
      }
      let patt1 = /"enableWeather":true/i
      if (bookmarks.some(x => patt1.test(x.definition))) {
        await new Promise(async function(resolve, reject) {
          await waitWeatherRequest(resolve)
        })
      }
      let patt2 = /"enableDateExtend":("statutory"|"custom")/i
      if (bookmarks.some(x => patt2.test(x.definition))) {
        await new Promise(async function(resolve, reject) {
          await waitFestivalsRequest(resolve)
        })
      }
    },

    resetChartOption(idx, optdata) {
      optdata.color = this.initChartColor(this.chartData[idx])
      this.$set(this.chartData[idx], 'option', optdata)
    },
    themeReset() {
      const projectopts = {
        basicInfo: this.basicInfo,
        theme: this.basicInfo.project.theme,
        mainColor: this.basicInfo.project.mainColor,
        pageunit: this.basicInfo.project.axisUnit,
        contrastTrendColor: this.basicInfo.project.contrastTrendColor,
        projectMalls: this.projectMalls,
        weathers: this.weathers,
        festivals: this.festivals,
      }
      this.chartData.forEach((x, i) => {
        if (x.version == '2.0') {
          if (x.searchCode === 'combination') {
            x.reqObj.cardlist.forEach((comitem, comi) => {
              if (comitem.cardtype !== 'word') {
                const cardinfos = comitem.cardinfos
                if (cardinfos) {
                  cardinfos.cardkey = comitem.cardinfos.cardkey + i + comi
                  cardinfos.projectopts = projectopts
                  this.$set(comitem, 'cardinfos', cardinfos)
                }
              }
            })
          } else {
            const cardinfos = x.cardinfos
            if (cardinfos) {
              cardinfos.cardkey = x.cardinfos.cardkey + i
              cardinfos.projectopts = projectopts
              this.$set(x, 'cardinfos', cardinfos)
            }
          }
        } else {
          if (x.data) {
            let cur = _this.chartData[x.idx]
            cur.option = null
            let option = {}
            if (!x.query.settings) {
              x.query.settings = {
                type: x.mode,
              }
            }
            const analysistype = x.temp == 'Template' ? x.query.parameter.usage : x.temp
            if (['funnel', 'relation'].includes(analysistype.toLowerCase())) {
              let temp = x.temp == 'Behavior' ? 'Behavior1' : x.temp
              let opts = {
                ...x.query.settings,
                analysisType: analysistype.toLowerCase(),
              }

              if (temp == 'Behavior1') {
                option = Behavior1(x.idx, x.query, x.data, x.inittableObj)
              } else if (temp == 'Retention') {
                option = Retention(x.idx, x.query, x.data, x.inittableObj)
              } else if (temp == 'Funnel') {
                option = Funnel(x.idx, x.query, x.data, x.inittableObj)
              } else if (temp == 'Association') {
                option = Association(x.idx, x.query, x.data, x.inittableObj)
              } else {
                option = Behavior(_this, x.styleset, x.data, opts, x.inittableObj, cur.hotmapdata, cur)
              }
            } else {
              let opts = {
                ...x.query.settings,
                analysisType: analysistype.toLowerCase(),
              }
              option = Behavior(_this, x.styleset, x.data, opts, x.inittableObj, cur.hotmapdata, cur)
            }
            option.color = _this.initChartColor(cur)

            _this.$set(_this.chartData[x.idx], 'option', option)
            setTimeout(() => {
              if (_this.$refs[`chart${x.idx}`] && _this.$refs[`chart${x.idx}`][0]) {
                let curchart = _this.$echarts.init(_this.$refs[`chart${x.idx}`][0].$refs[`cardref${x.idx}`][0])
                curchart.setOption(_this.chartData[x.idx].option, true)
                curchart.resize()
              }
            }, 20)
          }
        }
      })
    },
    createCopy(idx, newbookmark) {
      this.bookmarks.push(newbookmark)

      let posv2 = newbookmark.positionV2
      let pos = {
        id: newbookmark.id,
        x: posv2.col || posv2.col == 0 ? posv2.col : 4,
        y: posv2.row || posv2.row == 0 ? posv2.row : 4,
        w: posv2.x || posv2.x == 0 ? posv2.x : 4,
        h: posv2.y || posv2.y == 0 ? posv2.y : 4,
        i: this.bookmarks.length - 1,
      }
      this.gridData.push(pos)

      let chart = _.cloneDeep(this.chartData[idx])
      // 创建副本把钻取联动去掉
      chart.clickparams = []
      chart.id = newbookmark.id
      chart.index = this.bookmarks.length - 1

      if (chart.cardinfos) {
        chart.cardinfos.index = this.bookmarks.length - 1
        chart.cardinfos.cardkey = String(this.bookmarks.length - 1)
        chart.cardinfos.mapkey = String(this.bookmarks.length - 1) + '-'
        chart.cardinfos.settings.nameCN = newbookmark.name
      }
      chart.reqObj.setting.nameCN = newbookmark.name
      chart.reqObj.settings.nameCN = newbookmark.name
      chart.reqObj.title = newbookmark.name

      if (chart.injectsearchparam) {
        chart.injectsearchparam.id = newbookmark.id
      }
      let url = ''
      let path = newbookmark.category + '/' + this.projectID + '/' + newbookmark.id
      url = BI_config.analysisUrl + BI_config.analysisProxy + '/' + path
      chart.url = url
      this.chartData.push(chart)
      this.copyChartData.push(chart)
      this.dashkey++
    },
    async delbookMark(deltype, idx) {
      const _this = this
      let param = {
        dashboardID: this.$route.hash.replace('#/', ''),
        bookmarkID: this.bookmarks[idx].id,
      }
      _this.$loading({ lock: true, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' })
      try {
        const delres = deltype == 'delete' ? await deleteBookmark(this.bookmarks[idx].id) : await removeBookmark(param)
        _this.$loading({ lock: false }).close()
        if (delres && delres.error) return
        this.$message({
          type: 'success',
          message: '删除成功!',
        })
        const cpchart = JSON.parse(JSON.stringify(_this.chartData))
        const cpgrid = JSON.parse(JSON.stringify(_this.gridData))
        _this.gridData = []
        _this.chartData = []
        cpchart.splice(idx, 1)
        cpgrid.splice(idx, 1)
        _this.gridData = cpgrid.map((x, i) => {
          x.i = i
          return x
        })
        _this.chartData = cpchart.map((x, i) => {
          x.index = i
          return x
        })
        _this.bookmarks.splice(idx, 1)
        _this.copyChartData.splice(idx, 1)

        _this.dashkey++
        _this.gridready = false
      } catch (e) {
        _this.$loading({ lock: false }).close()
        _this.gridready = false
      }
    },
    emitDownloadExcel(chart, idx) {
      const filename = chart.reqObj.title
      let dataExportMode = chart.dataExportMode
      let taskType = '分析模型导出'
      let category = ''
      if (this.bookmarks && this.bookmarks.length > idx) {
        category = this.bookmarks[idx].category
      }
      if (dataExportMode == 2) {
        if (category == 'behavior' || this.chartData[idx].searchCode == 'behavior') {
          taskType = '事件分析导出'
        } else if (category == 'attribute' || this.chartData[idx].searchCode == 'attribute') {
          taskType = '属性分析导出'
        } else if (category == 'advance' || this.chartData[idx].searchCode == 'advance') {
          taskType = '聚合分析导出'
        } else if (category == 'segment' || this.chartData[idx].searchCode == 'segment') {
          taskType = '分群分析导出'
        } else if (category == 'combination' || this.chartData[idx].searchCode == 'combination') {
          taskType = '卡片组合导出'
        } else if (category == 'template' || this.chartData[idx].searchCode == 'template') {
          taskType = '模板分析导出'
        }
      }
      if (chart.settings.type === 'table_more') {
        const initoption =
          this.chartData[idx].searchCode == 'combination'
            ? this.$refs['carddashboard' + idx][0].$refs['chart' + chart.combinationidx][0].exportOption(chart.cardinfos.cardresizekey)
            : this.$refs['chart' + idx][0].exportOption(chart.cardinfos.cardresizekey)

        const tabidx = initoption.activeTabName.replace(/[^0-9]/gi, '')
        const curd = initoption.data[tabidx]
        const col = curd.tableHead.map(x => {
          if (x.usage === 'Group') {
            return {
              label: x.title,
              prop: x.alias,
              isGroup: true,
            }
          } else {
            return {
              clickableType: x.clickableType,
              displayType: x.displayType,
              fullAlias: x.fullAlias,
              label: x.title,
              prop: x.alias,
            }
          }
        })
        downloadExcel(filename, chart.settings, curd.sortData, col, chart.tableObj, chart.settings, dataExportMode, taskType)
      } else {
        const thead_orig = chart.inittableObj.thead_orig ? chart.inittableObj.thead_orig : chart.inittableObj.thead
        const sorttable = chart.sorttable ? chart.sorttable : chart.inittableObj.tbody
        const tbody_orig = chart.inittableObj.tbody_orig ? chart.inittableObj.tbody_orig : sorttable
        downloadExcel(filename, chart.settings, tbody_orig, thead_orig, chart.tableObj, chart.settings, dataExportMode, taskType)
      }
    },
    async drillSubmit(drilldata, idx) {
      const param = {
        dashboardID: this.$route.hash.replace('#/', ''),
        bookmarkID: this.bookmarks[idx].id,
      }
      // const res = await getDashboardBookmark(param)
      const dashboardID = this.$route.hash.replace('#/', '')
      const allmarksres = await getBookmarks(this.system, dashboardID)
      const findbookmark = allmarksres.data.find(x => x.id == this.bookmarks[idx].id)
      const list = {
        id: this.bookmarks[idx].id,
        name: findbookmark.nameCN || findbookmark.name,
        ...drilldata,
        ...findbookmark,
      }
      this.$set(this.bookmarks, idx, list)
      this.chartData[idx].cardinfos.bookmark = list
      if (this.chartData[idx].prefilter && this.chartData[idx].prefilter.length > 0) {
        this.drillclick('', idx, -1, 'closedrill')
      }
    },
    async linkageSubmit(oldlinkageset, idx) {
      // 联动保存 当前卡片和目标卡片都恢复初始状态
      let dashboardID = this.$route.query.dashboardID || this.$route.hash.replace('#/', '')
      const param = {
        dashboardID,
        bookmarkID: this.bookmarks[idx].id,
      }
      getDashboardBookmark(param).then(v => {
        this.$set(this.bookmarks[idx], 'linkageSetting', v.data.linkageSetting)
        let islinkaged = false
        if (!oldlinkageset) return
        oldlinkageset.bookmarks.forEach(x => {
          const findbookmark = this.bookmarks.find(b => b.id === x.id)
          const filters = findbookmark?.linkages?.filters ?? []
          if (filters && filters.length > 0) {
            islinkaged = true
            this.$set(findbookmark.linkages, 'filters', [])
            this.$set(findbookmark.linkages, 'groups', [])
            const findidx = this.bookmarks.findIndex(b => b.id === x.id)
            dashbdjs.searchchange(this, findidx, this.chartData[findidx].reqObj, '', '')
          }
        })
        if (islinkaged) {
          dashbdjs.searchchange(this, idx, this.chartData[idx].reqObj, '', '')
        }
      })
    },
    layoutCreatedEvent() {},
    layoutReadyEvent() {
      this.gridready = true

      // 订阅 截图功能 需要知道页面渲染完成, 目前方案是所有卡片加载完成后 页面新增一个标签 让服务端渲染是判断是否渲染完成
      // 判断卡片加载完成是 卡片的 loading 字段修改的时候
      const _this = this
      let time = 1
      let loop = setInterval(loopfunc, 1000)
      function loopfunc() {
        const findLoading = _this.chartData.find(x => x.loading)
        if (!findLoading || time == 180) {
          clearInterval(loop)
          _this.cardsRenderComplete = true
        } else {
          time++
        }
      }
    },

    resizeEvent(i, issign, state) {
      let _this = this
      if (state) {
        setTimeout(() => {
          if (_this.chartData[i].version !== '2.0') {
            _this.debouncefuc(i)
          }
        }, 300)
      } else {
        const time = issign ? 20 : 300
        clearTimeout(this.resizetimer)
        this.resizetimer = setTimeout(() => {
          if (_this.chartData[i].version !== '2.0') {
            _this.debouncefuc(i)
          }
        }, time)
      }
    },
    debouncefuc(i) {
      //多图组合重新绘制
      if (
        this.chartData.length > 0 &&
        this.chartData[i] &&
        this.chartData[i].searchCode == 'combination' &&
        this.$refs[`carddashboard${i}`] &&
        this.$refs[`carddashboard${i}`][0]
      ) {
        // this.$refs[`carddashboard${i}`][0] && this.$refs[`carddashboard${i}`][0].repaint()
      } else {
        const cardinfos = this.chartData[i].cardinfos
        if (!cardinfos) return
        const cardresizekey = cardinfos.cardresizekey
        if (this.chartData.length > 0 && this.chartData[i] && this.chartData[i].settings.type == 'map' && this.chartData[i].option && this.$refs[`chart${i}`]) {
          //热力图也需要重新绘制
          this.chartData[i].mapkey = this.chartData[i].mapkey + 1
        } else if (
          this.chartData.length > 0 &&
          this.chartData[i] &&
          this.chartData[i].settings.type == 'map3d' &&
          this.chartData[i].option &&
          this.$refs[`chart${i}`]
        ) {
          //3d地图也需要重新绘制
          this.$refs[`chart${i}`][0].$refs[`cardref${cardresizekey}`].updatemap()
        } else if (
          this.chartData.length > 0 &&
          this.chartData[i] &&
          ['table', 'table_hotmap', 'table_more', 'table_path'].includes(this.chartData[i].settings.type) &&
          this.chartData[i].option &&
          this.$refs[`chart${i}`] &&
          this.$refs[`chart${i}`][0]
        ) {
          let resizedidx = this.chartData[i].resizedidx + 1
          this.$set(this.chartData[i], 'resizedidx', resizedidx)
          this.$refs[`chart${i}`][0].$refs[`cardref${cardresizekey}`].$refs['tableref'].getMaxHeight()
        } else if (this.chartData.length > 0 && this.chartData[i] && this.chartData[i].option && this.$refs[`chart${i}`] && this.$refs[`chart${i}`][0]) {
          let mychart = this.$echarts.init(this.$refs && this.$refs[`chart${i}`][0].$refs[`cardref${cardresizekey}`])
          mychart.setOption(this.chartData[i].option, true)
          mychart.resize()
        }
      }
    },
    resizedEvent(i, newH, newW, newHPx, newWPx) {
      const _this = this
      clearTimeout(this.resizetimer)

      if (this.chartData[i].version == '2.0') {
        this.chartData.forEach((item, itemi) => {
          if (item.searchCode === 'combination') {
            item.reqObj.cardlist.forEach((comitem, comi) => {
              if (comitem.cardtype !== 'word' && comitem.cardinfos) {
                const cardresizekey = comitem?.cardinfos?.cardresizekey ?? 'c1234'
                this.$set(comitem.cardinfos, 'cardresizekey', cardresizekey + comi)
              }
            })
          } else {
            if (item.cardinfos) {
              const cardresizekey = item?.cardinfos?.cardresizekey ?? 'c1234'
              this.$set(item.cardinfos, 'cardresizekey', cardresizekey + itemi)
            }
          }
        })
      } else {
        this.resizetimer = setTimeout(() => {
          _this.debouncefuc(i)
        }, 50)
      }
    },
    movedEvent(i, newX, newY) {},
    layoutUpdatedEvent(newLayout) {
      const _this = this
      let newgrid = newLayout.map((x, idx) => {
        return {
          dashboardID: this.$route.hash.replace('#/', ''),
          bookmarkID: x.id,
          row: x.y,
          col: x.x,
          x: x.w,
          y: x.h,
        }
      })
      try {
        updateBookmarkPosition(newgrid).then(
          v => {
            this.gridData.map((x, i) => {
              this.$set(this.gridData, i, x)
            })
            this.copygridData = JSON.parse(JSON.stringify(this.gridData))
          },
          e => {
            _this.copygridData.forEach((x, i) => {
              _this.$set(_this.gridData, i, x)
              setTimeout(() => {
                if (_this.chartData[i].version !== '2.0') {
                  _this.debouncefuc(i)
                }
              }, 10)
            })
          }
        )
      } catch (error) {
        _this.copygridData.forEach((x, i) => {
          _this.$set(_this.gridData, i, x)
          setTimeout(() => {
            if (_this.chartData[i].version !== '2.0') {
              _this.debouncefuc(i)
            }
          }, 10)
        })
      }
    },
    // 批量查询 点击修改时 先循环一下书签 确定是否满足条件 然后修改查询条件 清空当前数据 请求接口赋值数据
    changeAlltime(time) {
      this.alltime = time
      if (this.chartData.length == 0) {
        return
      }

      const scheduler = new Scheduler(5, this.$route.fullPath)
      this.chartData.forEach((el, idx) => {
        if (el.timeRange || el.reqObj.timeRange || (el.reqObj.parameter && el.reqObj.parameter.timeRanges)) {
          if (el.version == '2.0') {
            if (el.searchCode == 'advance') {
              if (!el.timeRange) el.timeRange = []
              el.timeRange[0] = JSON.parse(JSON.stringify(this.alltime))
            } else {
              el.timeRange[0] = JSON.parse(JSON.stringify(this.alltime))
            }
          } else {
            el.reqObj.timeRange[0] = JSON.parse(JSON.stringify(this.alltime))
          }
          this.gettimeRange(time, el, idx, scheduler)
        }
      })
      scheduler.start()
    },
    allslotSearch(item) {
      this.chartData.forEach((el, idx) => {
        if (el.otherset.slot && this.slotshowarr.includes(el.otherset.slot)) {
          el.otherset.slot = item
          dashbdjs.searchchange(this, idx, el.reqObj, item, 'slot')
        }
      })
    },
    mallChange(item) {
      this.chartData.forEach((el, idx) => {
        if (el.reqObj.conditionGroup) {
          let conditions = el.reqObj.conditionGroup.conditions
          let curoperator = conditions.filter(c => {
            return c.operator === item.operator
          })
          if (curoperator.length > 0) {
            conditions.forEach((c, cidx) => {
              if (c.operator == item.operator) {
                if (item.values[0] == 'all') {
                  conditions.splice(cidx, 1)
                } else {
                  conditions[cidx] = item
                }
              }
            })
          } else {
            conditions.push(item)
          }
          dashbdjs.searchchange(this, idx, el.reqObj, item, 'mallid')
        }
      })
    },
    //刷新 对比 按天
    searchchange(idx, req, item, type, realtime) {
      this.chartData[idx].option = null
      dashbdjs.searchchange(this, idx, req, item, type, '', realtime)
    },
    batchChange() {
      let newitem = {}
      if (this.alltime) {
        newitem.v2time = [this.alltime]
      } else {
        newitem = ''
      }
      dashbdjs2.batchtime(this, this.bookmarks, newitem)
    },
    labelHead(h, { column, index }) {
      let l = column.label.length + 4
      let f = 16 //每个字大小，其实是每个字的比例值，大概会比字体大小差不多大一点，
      column.minWidth = f * l //字大小乘个数即长度 ,注意不要加px像素，这里minWidth只是一个比例值，不是真正的长度
      //然后将列标题放在一个div块中，注意块的宽度一定要100%，否则表格显示不完全
      return h('span', { class: 'table-head', style: { width: '100%' } }, [column.label])
    },
    startFull(idx) {
      this.fullchartidx = idx
      if (this.chartData[idx].version == '2.0') {
        if (this.chartData[idx].searchCode === 'combination') {
          this.chartData[idx].reqObj.cardlist.forEach((comitem, comi) => {
            if (comitem.cardtype !== 'word') {
              if (comitem.cardtype !== 'word' && comitem.cardinfos) {
                setTimeout(() => {
                  const cardresizekey = comitem?.cardinfos?.cardresizekey ?? 'c1234'
                  this.$set(comitem.cardinfos, 'cardresizekey', cardresizekey + comi)
                }, 50)
              }
            }
          })
        } else {
          setTimeout(() => {
            if (this.chartData[idx].cardinfos) {
              const cardresizekey = this.chartData[idx]?.cardinfos?.cardresizekey ?? 'c1234'
              this.$set(this.chartData[idx].cardinfos, 'cardresizekey', cardresizekey + 1)
            }
          }, 50)
        }
      } else {
        this.chartData[idx].cardkey++
        this.resizedEvent(idx)
      }
    },
    signFull(idx) {
      this.fullchartidx = -1
      if (this.chartData[idx].version == '2.0') {
        if (this.chartData[idx].searchCode === 'combination') {
          this.chartData[idx].reqObj.cardlist.forEach((comitem, comi) => {
            if (comitem.cardtype !== 'word' && comitem.cardinfos) {
              setTimeout(() => {
                const cardresizekey = comitem?.cardinfos?.cardresizekey ?? 'c1234'
                this.$set(comitem.cardinfos, 'cardresizekey', cardresizekey + comi)
              }, 50)
            }
          })
        } else {
          setTimeout(() => {
            if (this.chartData[idx].cardinfos) {
              const cardresizekey = this.chartData[idx]?.cardinfos?.cardresizekey ?? 'c1234'
              this.$set(this.chartData[idx].cardinfos, 'cardresizekey', cardresizekey + 1)
            }
          }, 50)
        }
      } else {
        this.chartData[idx].cardkey++
        this.resizeEvent(idx, true)
        this.resizedEvent(idx)
      }
    },
    chartsetidxChange(item) {
      this.chartsetidx = item
    },
    mouseleaveGridItem() {
      this.chartsetidx = -1
    },
    clickGridItem() {
      this.chartsetidx = -1
    },
    initChartColor(cur) {
      let mainColoridx = 0
      let mainColor = null
      let chartColoridx = 0
      if (cur.version == '2.0') {
        mainColoridx = cur.rdMainColor != null && cur.rdMainColor != '0' ? cur.rdMainColor : this.basicInfo.project.mainColor

        mainColor = cur.rdMainColor > 0 ? this.allColor['color' + (mainColoridx - 1)] : this.allColor['color' + mainColoridx]
        chartColoridx = cur.rdChartColor != null ? cur.rdChartColor : 0
      } else {
        mainColoridx = this.basicInfo.project.mainColor
        mainColor = this.allColor['color' + mainColoridx]
        chartColoridx = cur.rdChartColor != null ? cur.rdChartColor : 0
      }
      return mainColor[chartColoridx]
    },
    getCurrentColor() {
      if (this.rdMainColor == 0) {
        this.currentColor = this.basicInfo.project.mainColor == 0 ? this.allColor.lan : this.allColor.cheng
      } else {
        this.currentColor = this.rdMainColor == 1 ? this.allColor.lan : this.allColor.cheng
      }
    },
    cardMainColor(item) {
      let col = []
      let colors = ''
      if (item.version == '2.0') {
        if (item.rdMainColor == 0) {
          col = this.allColor['color' + this.basicInfo.project.mainColor]
        } else {
          col = this.allColor['color' + (item.rdMainColor - 1)]
        }
      } else {
        col = this.allColor['color' + this.basicInfo.project.mainColor]
      }

      colors = col[item.rdChartColor]
      let numberFontSize = item.option.numberFontSize
      let size = numberFontSize == 'auto' ? '' : numberFontSize
      let fontsize = size ? 'font-size:' + size : ''
      let sty = fontsize ? 'color:' + colors[0] + ';' + fontsize : 'color:' + colors[0]
      return sty
    },
    cardchange(i, item) {
      this.$set(this.chartData[i], 'curcardlabel', item)
      const curcard = this.chartData[i].option.arr.find(x => x.label === item)
      this.$set(this.chartData[i], 'curcard', curcard)
    },
    changeColorClose() {},
    getcounturl() {
      let url = ''
      return url
    },
    timechange(timeidx, time, el, idx) {
      if (timeidx === 1) {
        this.gettimeRange(time, el, idx)
      } else if (timeidx === 2) {
        this.gettimeRange2(time, el, idx)
      }
    },
    //时间修改1
    gettimeRange(time, el, idx, scheduler) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '', '', scheduler)
      } else {
        dashbdjs.gettimeRange(this, newitem, '', '', scheduler)
      }
    },
    //时间修改2
    gettimeRange2(time, el, idx) {
      let newitem = {}
      newitem.v2time = [time]
      newitem.index = idx
      newitem.item = el
      if (el.searchCode === 'combination') {
        cardinitMark.gettimeRange(this, newitem, BI_config, '', 'contrast')
      } else {
        dashbdjs.gettimeRange(this, newitem, '', 'contrast')
      }
    },
    changesort(item, idx) {
      this.chartData[idx].sorttable = item
    },
    drillclick(clickobj, idx, dri, closedrill) {
      if (this.system !== 'bi') return
      const { type } = clickobj
      if (!clickobj || type === 'drill') {
        linkageDrillJS.drillclick(this, idx, clickobj, dri, closedrill)
      } else if (type === 'linkage') {
        linkageDrillJS.linkageclick(this, idx, clickobj)
      }
    },
    initgroupname(idx, driidx) {
      return linkageDrillJS.initgroupname(this, idx, driidx)
    },
    initcardinfo(idx, cardinfo) {
      const bookmark = this.bookmarks[idx]
      if (bookmark && bookmark.drillSetting) {
        cardinfo.cardopts.isEnabledDrill = bookmark.drillSetting.isEnabled
      }
      if (bookmark && bookmark.linkageSetting) {
        cardinfo.cardopts.isEnabledLinkage = bookmark.linkageSetting.isEnabled
      }
      return cardinfo
    },

    confirmInjection(prefilter) {
      this.chartData.forEach((el, idx) => {
        el.reqObj.injectedArguments.prefilter = prefilter
        // this.$set(this.chartData[idx], 'reqObj', el.reqObj)
        dashbdjs.searchchange(this, idx, el.reqObj, '', '')
      })
    },
    init() {
      this.$pageunit = this.pageunit
    },

    // bus
    returnBookmarks() {
      return this.bookmarks
    },
  },
  async created() {
    this.allColor = allColor
    this.$basicInfo = this.basicInfo
    if (this.checkmobile) {
      this.debounce()
    }
  },
  mounted() {
    let _this = this
    this.init()

    // 注册全局调用方法 获取信息
    this.$eventBus.$on('getDashboardInfos', val => {
      if (val == 'bookmarks') {
        this.returnBookmarks()
      }
    })

    //浏览器变化 重新绘制图表
    window.addEventListener('resize', function() {
      // 变化后需要做的事
      clearTimeout(_this.resizetimer)
      _this.resizetimer = setTimeout(() => {
        _this.chartData.forEach((x, idx) => {
          if (x.version !== '2.0') {
            _this.debouncefuc(idx)
          }
        })
      }, 300)
    })
  },
}
</script>

<style lang="scss">
@import '@/assets/style/pages/dashboard.scss';
</style>

<style>
.vue-grid-item {
  transition: none;
}
</style>
