<template>
  <div v-if="mapConfig" class="bi-map">
    <div class="bi-map-body">
      <div
        v-if="mapConfig.svg"
        :id="'map' + idx"
        class="bi-map-inner"
        :style="mouseStyle"
        v-html="mapConfig.svg"
        @mousedown="handleMousedown"
        @mousemove="handleMousemove"
        @mouseup="handleMouseup"
        @mouseleave="handleMouseleave"
      ></div>
      <div v-else class="no-mapdata">暂无信息</div>
      <div v-if="gTagHtml" id="g_txt" class="gTxt" :style="gTxtStyle" v-html="gTagHtml"></div>
      <div v-if="toastHtml" class="map-toast" :style="mapToastStyle" v-html="toastHtml"></div>
    </div>
    <div class="bi-label-list analysisV2" v-if="mapConfig.labelData">
      <div class="floor" v-text="mapConfig.floor"></div>
      <div class="label-box">
        <span class="min">0</span>
        <div class="bi-label-item" v-for="(item, index) in mapConfig.labelData" :key="index">
          <span class="label-color" :style="'background-color:' + item.bgColor"></span>
          <span class="label-name" v-text="item.labelName"></span>
        </div>
        <span class="max" v-text="mapConfig.max"></span>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
//require('jquery-mousewheel')($)
import 'jquery-mousewheel'

export default {
  name: 'BIMap',
  props: {
    mapConfig: {
      type: Object,
      default: null,
    },
    idx: {
      type: Number,
    },
  },
  data() {
    return {
      mapData: {},
      mouseStyle: '',
      mapToastStyle: '',
      hasMove: false,
      toastHtml: '',
      gTagHtml: '',
      gTxtStyle: '',
    }
  },
  watch: {
    hasMove: {
      handler(val) {
        if (val) {
          if (this.mapData.state == 'drag') {
            this.mouseStyle = 'cursor: move;'
          } else {
            this.mouseStyle = ''
          }
        } else {
          this.mouseStyle = ''
        }
      },
    },
    mapConfig: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.convertSvgProp()
          })
        }
      },
    },
  },
  computed: {
    mapStyle() {
      if (this.mapConfig) {
        // let { width, height } = this.mapConfig
        // let w = String(width).indexOf('%') !== -1 ? width : width + 'px',
        //   h = String(height).indexOf('%') !== -1 ? height : height + 'px',
        //   s = `width:${w};height:${h};`
        // return s
      } else {
        return ''
      }
    },
  },
  methods: {
    reDrawSvg() {
      let s = this.mapData.svg

      $(s).attr({ width: '100%', height: '100%' })

      //设置地板颜色
      $(s)
        .find('g')
        .find('._area_background')
        .eq(0)
        .find('path')
        .attr({ fill: '#ffffff' })

      //设置设施商户初始颜色
      $(s)
        .find('g')
        .find('g')
        .not('._area_background')
        .find('path')
        .attr({ fill: this.mapConfig.defaultFill })
        .end()
        .find('polygon')
        .attr({ fill: this.mapConfig.defaultFill })
        .end()
        .find('rect')
        .attr({ fill: this.mapConfig.defaultFill })
        .end()
        .find('polyline')
        .attr({ fill: this.mapConfig.defaultFill })

      //设置选中商户颜色
      this.mapConfig.shopData.forEach((item, index) => {
        $(s)
          .find('#' + item.mapAreaId)
          .find('path')
          .attr({ fill: item.Fill })
          .end()
          .find('polygon')
          .attr({ fill: item.Fill })
          .end()
          .find('rect')
          .attr({ fill: item.Fill })
      })

      this.gTxtStyle = `color:${this.mapConfig.gTxtColor}`
    },
    convertSvgProp() {
      const home = document.getElementById('mblayoutid')
      let mapid = '#map' + this.idx
      let s = (this.mapData.svg = $(mapid + ' svg')[0]),
        g = (this.mapData.vp = $(mapid + ' #viewport')[0])

      this.reDrawSvg()

      let matrix = this.getctm()
      this.setCTM(g, matrix)
      let view = []
      if ($(s).length && $(s).attr('viewBox')) {
        view = $(s)
          .attr('viewBox')
          .match(/(\+|-)?[\d\.]+/g)
      }
      $(s).removeAttr('viewBox')

      this.mapData.ratio = matrix.a
      this.mapData.scale = matrix.a

      this.mapData.posX = matrix.e
      this.mapData.posY = matrix.f

      this.mapData.box_w = $(s).width()
      this.mapData.box_h = $(s).height()

      this.mapData.vb_w = parseFloat(view[2]) - parseFloat(view[0])
      this.mapData.vb_h = parseFloat(view[3]) - parseFloat(view[1])

      this.mapData.r_w = 0.5
      this.mapData.r_h = 0.5

      this.mapData.state = '' //是否是在拖拽
      this.mapData.offsetLeft = $(mapid + ' svg').offset().left
      this.mapData.offsetTop = home ? $(mapid + ' svg').offset().top + home.scrollTop + 50 : $(mapid + ' svg').offset().top
      this.mapData.markData = this.formatMarkData(this.mapConfig.markData)
      this.mapData.visiblenodes = [] //可见节点
      this.mapData.viewportAreaShop = [] //视口内节点

      this.mapData.isScale = false //是否在缩放

      this.showMarkData()

      $(mapid).mousewheel((e, delta) => {
        e.preventDefault()
        this.handleMousewheel(e, delta)
      })
    },
    handleMousewheel(e, delta) {
      let z = Math.pow(1 + this.mapConfig.zoomScale, delta),
        s = this.mapData.svg

      let p = this.getEventPoint(e).matrixTransform(this.getctm().inverse())

      let k = s
        .createSVGMatrix()
        .translate(p.x, p.y)
        .scale(z)
        .translate(-p.x, -p.y)

      let matrix = this.getctm().multiply(k)

      if (matrix.a <= this.mapData.ratio) return
      if (!this.mapData.isScale) this.mapData.isScale = true
      this.mapshow(matrix)

      if (typeof this.mapData.stateTf == 'undefined') {
        this.mapData.stateTf = this.getctm().inverse()
      }
      this.mapData.stateTf = this.mapData.stateTf.multiply(k.inverse())
    },
    handleMousedown(e) {
      let s = this.mapData.svg
      this.hasMove = false

      let $svg = $(e.target).closest(s)

      if ($svg.length) {
        this.mapData.state = 'drag'
        this.mapData.stateTf = this.getctm().inverse()
        this.mapData.stateOrigin = this.getEventPoint(e).matrixTransform(this.mapData.stateTf)
      }
    },
    handleMousemove(e) {
      this.showToast(e)

      if (this.mapData.isScale) this.mapData.isScale = false
      let g = this.mapData.vp,
        $vp = $(e.target).closest(this.mapData.vp),
        pos = this.offset(e),
        x = pos.x,
        y = pos.y,
        pos_svg = this.calcPosSvg(x, y),
        x1 = pos_svg.x,
        y1 = pos_svg.y

      this.hasMove = true

      if (this.mapData.state == 'drag') {
        let s = this.mapData.stateOrigin
        let p = this.getEventPoint(e).matrixTransform(this.mapData.stateTf)
        x = p.x - s.x
        y = p.y - s.y
        let matrix = this.mapData.stateTf.inverse().translate(x, y)
        this.mapshow(matrix)
      }
    },
    handleMouseup(e) {
      this.mapData.state = ''
      this.hasMove = false
    },
    handleMouseleave() {
      this.showMapToast = false
      this.mapToastStyle = ''
      this.mapData.state = ''
      this.hasMove = false
    },
    getEventPoint(e) {
      let p = this.mapData.svg.createSVGPoint(),
        pos = this.offset(e)
      p.x = pos.x
      p.y = pos.y
      return p
    },
    calcPosSvg(x, y) {
      // 根据x,y,计算svg内部x,y
      let matrix = this.getctm()
      let scale = matrix.a
      let x1 = matrix.e
      let y1 = matrix.f
      let x2 = ((x - x1) / scale).toFixed(3)
      let y2 = ((y - y1) / scale).toFixed(3)
      return { x: x2, y: y2 }
    },
    getctm() {
      let g = this.mapData.vp
      return g.getCTM() || g.getScreenCTM()
    },
    setCTM(element, matrix) {
      let m = `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${matrix.e},${matrix.f})`
      element.setAttribute('transform', m)
    },
    offset(e) {
      // svg内x,y]
      const home = document.getElementById('mblayoutid')
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYoffset || 0
      if (home) {
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop || home.scrollTop || 0
      }
      let obj = { x: e.clientX - this.mapData.offsetLeft + document.body.scrollLeft, y: e.clientY - this.mapData.offsetTop + scrollTop }
      return obj
    },
    mapshow(matrix) {
      /* 边界,
       * 长宽如果小于容器时,定位居中/2
       * 大于容器时, 加上差额
       * 小于容器 禁止移动
       */
      let g = this.mapData.vp,
        r_bound = 0,
        l_bound = 0,
        t_bound = 0,
        b_bound = 0, //上拉svg 底部极限 计算top
        box_h = this.mapData.box_h,
        box_w = this.mapData.box_w,
        box_h1 = this.mapData.vb_h,
        box_w1 = this.mapData.vb_w,
        gap_h = 0, //上半部分空隙
        gap_w = 0, //左半部分空隙
        mapGap = this.mapConfig.mapGap,
        posX = matrix.e,
        posY = matrix.f,
        ratio = this.mapData.ratio

      this.mapData.scale = matrix.a

      if (this.mapData.scale < ratio) {
        this.mapData.scale = matrix.a = matrix.d = ratio
      }

      if (this.mapData.scale >= this.mapConfig.maxScale) return

      gap_h = box_h - box_h1 * this.mapData.scale
      gap_w = box_w - box_w1 * this.mapData.scale

      // 空隙大于阀值 不移动, 小于阀值时,可移动阀值区域
      if (gap_h > mapGap) {
        t_bound = gap_h / 2
        b_bound = gap_h / 2
      } else {
        t_bound = mapGap
        b_bound = gap_h - mapGap
      }

      if (gap_w > mapGap) {
        l_bound = gap_w / 2
        r_bound = gap_w / 2
      } else {
        l_bound = mapGap
        r_bound = gap_w - mapGap
      }

      if (posX < r_bound) {
        posX = r_bound
      }
      if (posY < b_bound) {
        posY = b_bound
      }

      // 优先处理左上角边界,覆盖上面的右上
      if (posX > l_bound) {
        posX = l_bound
      }
      if (posY > t_bound) {
        posY = t_bound
      }

      matrix.e = posX
      matrix.f = posY

      this.mapData.posX = posX
      this.mapData.posY = posY

      this.showMarkData()
      this.setCTM(g, matrix)
    },
    showToast(e) {
      let id = $(e.target)
        .parent('g')
        .attr('id')

      let item = this.mapConfig.shopData.find(item => item.mapAreaId == id)

      if (item && !this.isMove) {
        let pos = this.offset(e),
          x = pos.x,
          y = pos.y
        this.mapToastStyle = `display: block;left:${x + 10}px;top: ${y + 10}px;`
        this.toastHtml = item.toastHtml
      } else {
        this.mapToastStyle = ''
        this.toastHtml = ''
      }
    },
    formatMarkData(markData) {
      let arr = {}

      markData.forEach(item => {
        let mapareaid = item.mapAreaId.replace(/\//g, '-'),
          _ref,
          w
        if ($('#' + mapareaid).length != 0) {
          _ref = this.getAreaCenter($('#' + mapareaid))
          w = this.getBytesWidth(item.name)
          arr[mapareaid] = [mapareaid, _ref.x, _ref.y, w, item.name, item.bizType]
        }
      })

      return arr
    },
    getBytesWidth(str) {
      var span = document.createElement('span'),
        txt = document.createTextNode(str),
        w = 1
      span.appendChild(txt)
      document.body.appendChild(span)
      w = span.offsetWidth
      document.body.removeChild(span)
      return w
    },
    getAreaCenter(el) {
      let cx, cy, height, rect, width, x, x1, x2, y, y1, y2, _ref
      if (!el.length) {
        return { x: 0, y: 0 }
      }
      if (el.attr('cxy') != null && el.attr('cxy') !== '') {
        _ref = el.attr('cxy').split(',')
        cx = _ref[0]
        cy = _ref[1]
      } else {
        rect = el.find('rect')
        if (rect[0] != null) {
          _ref = [
            rect.attr('x'),
            rect.attr('y'),
            parseFloat(rect.attr('x')) + parseFloat(rect.attr('width')),
            parseFloat(rect.attr('y')) + parseFloat(rect.attr('height')),
          ]
          x1 = _ref[0]
          y1 = _ref[1]
          x2 = _ref[2]
          y2 = _ref[3]
          cx = (parseFloat(x1) + parseFloat(x2)) / 2
          cy = (parseFloat(y1) + parseFloat(y2)) / 2
        } else {
          _ref = el[0].getBBox()
          x = _ref.x
          y = _ref.y
          width = _ref.width
          height = _ref.height
          cx = x + width / 2
          cy = y + height / 2
        }
      }
      return {
        x: parseFloat(cx),
        y: parseFloat(cy),
      }
    },
    // 根据铺位id或x,y,计算基于视口(0,0)的x,y
    id_calcPos(...arg) {
      var $area, _ref, x, y
      if (arg.length > 1) {
        x = arg[0] * this.mapData.scale + this.mapData.posX
        y = arg[1] * this.mapData.scale + this.mapData.posY
      } else if (arg.length == 1) {
        $area = $('#' + arg[0])
        _ref = this.getAreaCenter($area)
        x = _ref.x * this.mapData.scale + this.mapData.posX
        y = _ref.y * this.mapData.scale + this.mapData.posY
      }
      return { x: x, y: y }
    },
    getViewPortCenter() {
      // 视口中心点的左侧占比*总长度 等于 视口中心x,y
      return {
        x: this.mapData.vb_w * this.mapData.r_w,
        y: this.mapData.vb_h * this.mapData.r_h,
      }
    },
    // 获取位移比率,以容器中心点为基点,放大后左半边的占比(中心点+左位移 除以 总大小)
    getPosRatio(scale) {
      return {
        r_w: (this.mapData.box_w / 2 - this.mapData.posX) / (this.mapData.vb_w * scale),
        r_h: (this.mapData.box_h / 2 - this.mapData.posY) / (this.mapData.vb_h * scale),
      }
    },
    getBoundTxt(arr, s) {
      let id = arr[0],
        x = arr[1],
        y = arr[2],
        len = arr[3],
        name = arr[4]
      return {
        i: id,
        name: name,
        l: x,
        t: y,
        r: x + len / s,
        b: y + 14 / s,
      }
    },
    getBoundHd(arr, s, imagesize) {
      let id = arr[0],
        x = arr[1],
        y = arr[2]
      return {
        i: id,
        l: x,
        t: y,
        r: x + imagesize / s,
        b: y + imagesize / s,
      }
    },
    //验证元素是否碰撞
    checkIsCovered(type, arr, visible) {
      let flag = false,
        rect,
        rect1,
        txtGap = this.mapConfig.txtGap,
        tGap,
        scale = this.mapData.scale

      if (type == 'txt') {
        rect = this.getBoundTxt(arr, scale)
        tGap = txtGap / scale
      } else if (type == 'hd') {
        rect = this.getBoundHd(arr, scale, imagesize)
        tGap = 10 / scale
      }
      let left = rect.l - tGap
      let top = rect.t - tGap
      let right = rect.r + tGap
      let bottom = rect.b + tGap

      for (let i = 0, n = visible.length; i < n; i++) {
        if (type == 'txt') {
          rect1 = this.getBoundTxt(visible[i], scale)
        } else if (type == 'hd') {
          rect1 = this.getBoundHd(visible[i], scale, imagesize)
        }
        let left1 = rect1.l
        let top1 = rect1.t
        let right1 = rect1.r
        let bottom1 = rect1.b

        //左边
        if (left < left1 && right > left1 && !(top > bottom1 || bottom < top1)) {
          flag = true
          break
        }

        //右边
        if (left < right1 && right > right1 && !(top > bottom1 || bottom < top1)) {
          flag = true
          break
        }

        //上边
        if (top < top1 && bottom > top1 && !(right < left1 || left > right1)) {
          flag = true
          break
        }

        //下边
        if (top < bottom1 && bottom > bottom1 && !(right < left1 || left > right1)) {
          flag = true
          break
        }

        //内部上下
        if (left > left1 && right < right1 && !(top > bottom1 || bottom < top1)) {
          flag = true
          break
        }

        //内部左右
        if (top > top1 && bottom < bottom1 && !(right < left1 || left > right1)) {
          flag = true
          break
        }
      }

      return flag
    },
    setText(arr) {
      let strHtml = ''

      for (let i = 0; i < arr.length; i++) {
        let item = arr[i],
          mapareaid = item[0],
          w = item[3],
          name = item[4],
          type = item[5] == 1 ? 'm' : 'p', // 判断是否 车位或商铺
          noDot = item[6] || false,
          _pos = this.id_calcPos(mapareaid)

        if (noDot) {
          strHtml += `<span id="m_${mapareaid}" class="cls_txt cls_txt1" style="left:${_pos.x}px;top: ${_pos.y}px;margin-left: ${-w /
            2}px;" data-type="${type}">${name}</span>`
        } else {
          strHtml += `<span id="m_${mapareaid}" class="cls_txt cls_txt1 cls_txt_circle" style="left:${_pos.x}px;top: ${
            _pos.y
          }px;" data-type="${type}">${name}</span>`
        }
      }

      this.gTagHtml = strHtml
    },
    shopViewPortArea() {
      // 起点坐标:lastPos-差额部分算出, 终点坐标:起点+视口宽高
      let x1 = -this.mapData.posX / this.mapData.scale,
        y1 = -this.mapData.posY / this.mapData.scale,
        x2 = x1 + this.mapData.box_w / this.mapData.scale,
        y2 = y1 + this.mapData.box_h / this.mapData.scale
      return [x1, y1, x2, y2]
    },
    // 获取视口商户
    shopViewPort: function() {
      // 循环当前商铺坐标, 保存当前在视口内的商户id
      var _this = this,
        area = this.shopViewPortArea(),
        x1 = area[0],
        y1 = area[1],
        x2 = area[2],
        y2 = area[3],
        arr = [] //初始化视口商铺
      $('._area_merchant,._area_parking').each(function() {
        var x0, y0, id, bbox
        var bbox = _this.getAreaCenter($(this))
        x0 = bbox.x
        y0 = bbox.y
        if (x1 < x0 && x0 < x2 && y1 < y0 && y0 < y2) {
          arr.push($(this).attr('id'))
        }
      })
      // 视口内 商户,设备id
      this.mapData.viewportAreaShop = arr
    },
    //更新位移比率
    updateRwRh() {
      let r = this.getPosRatio(this.mapData.scale)
      this.mapData.r_w = r.r_w
      this.mapData.r_h = r.r_h
    },
    showMarkData() {
      this.updateRwRh()
      this.shopViewPort()

      if (this.mapData.isScale) {
        this.gTagHtml = ''
        this.mapData.visiblenodes = []
      }

      let scale = this.mapData.scale,
        arrTxt = this.mapData.markData,
        arr = this.mapData.viewportAreaShop, //视口内数据
        visiblenodes = [], //本次数据
        last_visible = this.mapData.visiblenodes, //上一次数据
        new_temp_arrs = [] //新增数据

      // 循环上一次结果,筛选变化的数据, drag 状态下
      if (last_visible && this.mapData.state == 'drag') {
        for (var i = 0, n = last_visible.length; i < n; i++) {
          var id = last_visible[i][0]
          if (arr.contains(id)) {
            visiblenodes.push(arrTxt[id].slice(0))
            arr.splice(arr.indexOf(id), 1)
          } else {
            $('#m_' + id).remove()
          }
        }
      }

      for (var i = 0, n = arr.length; i < n; i++) {
        var id = arr[i]
        if (!arrTxt[id]) {
          continue
        }

        // 临时数组, 存储变化
        var temp_arr = arrTxt[id].slice(0),
          textWidth = temp_arr[3]
        // 总体默认加上圆点padding,精确比对值
        temp_arr[3] += 12
        // 上移半个汉字,模拟汉字垂直居中
        temp_arr[2] -= 14 / 2 / scale
        // 当ratio非常小,scale=2时,依然很小,故ratio*scale=2,为实际2倍,文字才可能小于铺位尺寸,开放文字居中
        if (scale > 2) {
          var g = $('#' + id)
              .children()
              .first()[0],
            w = g.getBoundingClientRect().width,
            h = g.getBoundingClientRect().height
          // 临时数组, 存储居中偏移参数
          if (w > textWidth + 10) {
            temp_arr[3] = textWidth // 没有圆点时,恢复宽度
            temp_arr[1] -= textWidth / 2 / scale //模拟x坐标居中
            temp_arr[6] = 1
          }
        }
        // false为不覆盖
        if (!this.checkIsCovered('txt', temp_arr, visiblenodes)) {
          visiblenodes.push(temp_arr)
          new_temp_arrs.push(temp_arr)
        }
      }
      this.setText(new_temp_arrs)
      this.visiblenodes = visiblenodes
    },
  },
}
</script>

<style lang="scss" scoped>
.bi-map {
  position: relative;
  user-select: none;
}
.bi-map-body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 25px);
  // overflow: hidden;
  .bi-map-inner {
    width: 100%;
    height: 100%;
  }
}
.bi-label-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  text-align: center;
  user-select: none;
  padding: 14px 0;
  .bi-label-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    margin-bottom: 10px;
    .label-color {
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 12px;
      margin-right: 4px;
    }
    .label-name {
      font-size: 12px;
    }
  }
}
.no-mapdata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
}
.map-toast {
  position: absolute;
  background: rgba(#000, 0.7);
  color: #fff;
  padding: 18px 14px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.6;
  white-space: nowrap;
  ::v-deep dl {
    dt {
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 5px;
    }
  }
}
.gTxt {
  font-size: 12px;
  pointer-events: none;
  color: #666;
  ::v-deep span {
    position: absolute;
    font-size: 12px;
    margin-top: -7px;
    white-space: nowrap;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.8);
    &.cls_txt_circle {
      padding-left: 12px;
      background: url('https://i1.mallcoo.cn/sp_mall/62hep6ee-dae7-48e3-b95f-cd9125f9f7e4.png') no-repeat 0 50%;
      background-size: 8px 8px;
    }
  }
}

.analysisV2 {
  height: 16px;
  top: 0;
  padding: 0;
  text-align: left;
  display: flex;
  background: none;
  .floor {
    display: inline-block;
    @include theme_color2($theme-light);
  }
  .label-box {
    display: inline-block;
    margin-left: 20px;
    display: flex;
    align-items: center;
    @include theme_color2($theme-light);
    .min {
      margin-right: 3px;
    }
    .bi-label-item {
      padding: 0 3px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      .label-name {
        display: none;
      }
    }
  }
}

.mobilebody {
  .analysisV2 {
    .label-box {
      margin-left: 10px;
      .bi-label-item {
        padding: 0;
      }
    }
  }
}
</style>
