<template>
  <div class="groupDilog">
    <div class="search">
      <el-input v-model="searchWords" placeholder="请输入内容" @input="filtKeyup"></el-input>
    </div>
    <div class="fields" :key="listkey">
      <template v-if="datasource == 'entity'">
        <div class="groupitem" v-for="(item, index) in showgroup" :key="index">
          <div class="tit">{{ item.groupName }}</div>
          <div class="group">
            <ul>
              <li v-for="(n, nidx) in item.child" @click="add(index, n)" :key="nidx">
                <span>{{ n.name }}</span>
                <i class="wenhao iconfont iconwenhao4 wehhaoicon" v-if="showRemarkFlag != n.code && n.description" @click.stop="showRemarkFlag = n.code"></i>
                <el-popover v-if="showRemarkFlag == n.code" popper-class="wenhao-pop" placement="bottom-start" title="" width="300" trigger="hover">
                  {{ n.description }}
                  <i
                    class="wenhao iconfont iconwenhao4 wehhaoicon"
                    slot="reference"
                    @mouseleave="showRemarkFlag = null"
                    @click.stop="showRemarkFlag = null"
                  ></i>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="groupitem" v-for="(item, index) in showgroup" :key="index">
          <div class="tit">{{ item.groupName }}</div>
          <div class="group" v-for="(g, gidx) in item.child" v-show="g.properties.length > 0" :key="gidx">
            <div class="label" v-show="g.groupName">{{ g.groupName }}：</div>
            <ul>
              <li v-for="(n, nidx) in g.properties" @click="add(index, n)" :key="nidx">
                <span>{{ n.name }}</span>
                <i class="wenhao iconfont iconwenhao4 wehhaoicon" v-if="showRemarkFlag != n.code && n.description" @click.stop="showRemarkFlag = n.code"></i>
                <el-popover v-if="showRemarkFlag == n.code" popper-class="wenhao-pop" placement="bottom-start" title="" width="300" trigger="hover">
                  {{ n.description }}
                  <i
                    class="wenhao iconfont iconwenhao4 wehhaoicon"
                    slot="reference"
                    @mouseleave="showRemarkFlag = null"
                    @click.stop="showRemarkFlag = null"
                  ></i>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['filterTemp', 'groupdata', 'rowindex', 'operator', 'datasource'],
  data() {
    return {
      searchWords: '',
      showgroup: null,
      copygroup: null,
      showRemarkFlag: null,
      listkey: 0,
    }
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
      projectMalls: state => state.projectMalls,
    }),
  },
  created() {
    this.showgroup = JSON.parse(JSON.stringify(this.groupdata))
  },
  methods: {
    add(idx, groupItem) {
      let ope = this.operator.filter(x => {
        return x.dataTypes.includes(groupItem.dataType)
      })[0].modes
      if (groupItem.dataType == 'Date') {
        groupItem.dataOperators = ope
      }
      if (groupItem.dataType == 'Geo') {
        this.$set(groupItem, 'geoValue', this.projectMalls[0].id)
      }
      this.$set(groupItem, 'operators', ope[0].operators)
      this.$set(groupItem, 'mode', ope[0].modeCode)
      this.$set(groupItem, 'modeCode', ope[0].modeCode)
      this.$set(groupItem, 'operator', ope[0].operators[0].code)
      this.$set(groupItem, 'canAutoComplete', ope[0].operators[0].canAutoComplete)
      this.$set(groupItem, 'canMultiple', ope[0].operators[0].canMultiple)
      this.$set(groupItem, 'quantity', ope[0].operators[0].quantity)
      this.$set(groupItem, 'values', [])
      this.$set(groupItem, 'tags', [])
      let d = JSON.parse(JSON.stringify(this.groupdata))
      let event = d[idx]
      // groupItem.eventID = event.id ? event.id : null
      groupItem.eventID = null
      groupItem.showname = event.id ? event.groupName + '的' + groupItem.name : groupItem.name

      if (this.isSameGroup(groupItem, d)) {
        groupItem.hassame = true
      }
      this.$set(this.filterTemp, this.rowindex, groupItem)
      this.$set(this.filterTemp[this.rowindex], 'popVisible', false)
      this.$emit('change-filtermame', this.filterTemp)
    },
    filtKeyup() {
      this.copygroup = JSON.parse(JSON.stringify(this.groupdata))
      if (this.searchWords) {
        this.showgroup = this.copygroup.map(x => {
          if (x.child && x.child.length > 0 && x.child[0].properties) {
            x.child = x.child.map(k => {
              k.properties = k.properties.filter(p => {
                return this.$commonJs2.matchSoftly(p.name, this.searchWords)
              })
              return k
            })
          } else {
            x.child = x.child.filter(k => {
              return this.$commonJs2.matchSoftly(k.name, this.searchWords)
            })
          }
          return x
        })
        this.listkey++
      } else {
        this.showgroup = JSON.parse(JSON.stringify(this.groupdata))
      }
    },
    isSameGroup(item, d) {
      let samename = true
      let curevt = []
      d.forEach(g => {
        if (g.child && g.child.length > 1 && g.child[0].properties) {
          g.child.forEach(c => {
            const cur = c.properties.find(p => {
              return p.code == item.code && p.name == item.name
            })
            if (cur) {
              curevt = g.child
            }
          })
        }
      })
      curevt.forEach(c => {
        const cur = c.properties.find(p => {
          return p.name == item.name
        })
        if (!cur) {
          samename = false
        }
      })
      return curevt.length > 0 ? samename : false
    },
  },
}
</script>
<style lang="scss" scoped>
.wehhaoicon {
  margin-left: 5px;
}
.groupDilog {
  font-size: 14px;
  .fields {
    max-height: 500px;
    overflow-y: scroll;
    margin-top: 10px;
    @include theme_bg1($theme-light);
    .groupitem {
      margin-bottom: 10px;
    }
    .tit {
      line-height: 32px;
      font-size: 14px;
      color: #303133;
      @include theme_color2($highcolor-cheng);
      @include theme_border_color($theme-border-light);
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      font-weight: bold;
    }
    .group {
      display: flex;
      margin-top: 10px;
      .label {
        line-height: 34px;
      }
      ul {
        flex: 1;
        overflow: hidden;
        li {
          width: calc(33% - 10px);
          float: left;
          height: 34px;
          line-height: 34px;
          padding: 0 10px 0 5px;
          margin: 5px;
          margin-top: 0;
          cursor: pointer;
          @include theme_color2($highcolor-cheng);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover {
            @include high_color1($highcolor-cheng);
          }
          &.selected {
            color: #999;
            background: #f5f7fa;
            cursor: not-allowed;
          }
          > span {
            display: inline-block;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
