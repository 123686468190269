<template>
  <el-drawer
    :title="bookmark.linkageSetting && bookmark.linkageSetting.bookmarks ? '编辑卡片联动' : '创建卡片联动'"
    custom-class="linkageDrawer"
    :visible.sync="drawer"
    direction="rtl"
    :append-to-body="true"
    :size="700"
  >
    <div class="linkagebox">
      <div class="currentcard">
        <h3>
          当前卡片及使用字段：<el-popover popper-class="wenhao-pop" placement="right-start" title="" width="370" trigger="click">
            <div>使用字段即该卡片搭建页面设置的最后一个分组方式</div>
            <i class="wenhao iconfont wenhaoicon" slot="reference"></i>
          </el-popover>
        </h3>
        <span>{{ bookmark.name }}（{{ lastgroup.name }}）</span>
      </div>
      <div class="targetcard">
        <h3>目标卡片及关联字段：</h3>
        <div class="list">
          <el-checkbox-group v-model="checkList">
            <div class="checkboxitem" v-for="card in cardlist" :key="card.id">
              <el-checkbox :disabled="!card.canVisit" :label="card.id">{{ card.name }}</el-checkbox>
              <el-popover
                v-if="checkList.includes(card.id)"
                popper-class="fields"
                placement="left"
                width="660"
                trigger="click"
                v-model="card.popVisible"
                :key="card.groupkey"
              >
                <groupPopper :groupdata="card.groupData" @change-group="g => changeGroup(card, g)" />
                <el-button class="groupshowname" placeholder="请选择" size="mini" :disabled="!card.canVisit" slot="reference">
                  {{ card.showname }}<i class="el-icon-caret-bottom"></i>
                </el-button>
              </el-popover>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button class="cancel" size="small" @click="cancel">取 消</el-button>
      <el-button type="primary" size="small" @click="submit">保 存</el-button>
    </div>
  </el-drawer>
</template>

<script>
// 联动功能实现：初始化boonmarks 每一项新增对象 linkages：{groups:[],filters:[]}如果有联动的卡片给关联卡片groups新增一条分组数据；
// 点击柱子时，如果相关卡片filters 没有点击传递的筛选条件 新增一条，如果有 替换掉。

import { mapState } from 'vuex'
import _ from 'lodash'
import BI_config from '@/utils/config'
import groupPopper from './groupPopper.vue'
import { putLinkage } from '@/api/apiV2_dashboard'
import linkageJS from '../js/linkagejs'

export default {
  props: ['bookmark', 'bookmarks'],
  data() {
    return {
      drawer: false,
      cardlist: [],
      checkList: [],
      allBehaviorMeta: [],
      allAttributeMeta: [],

      lastgroup: null,
    }
  },
  components: { groupPopper },
  computed: {
    ...mapState({
      allcards: state => state.allcards,
      projectID: state => state.projectID,
      behaviorMeta: state => state.behaviorMeta,
      attributeMeta: state => state.attributeMeta,
    }),
  },
  created() {
    this.initMeta()
    this.lastgroup = linkageJS.getlastgroup(this.bookmark)
    this.initList()
  },
  watch: {
    drawer: function() {
      this.$emit('closeLinkage', this.drawer)
    },
  },
  methods: {
    initList() {
      const otherBookmarks = this.bookmarks.filter(x => x.id != this.bookmark.id)
      const settingsarr = this.bookmark?.linkageSetting?.bookmarks ?? []
      this.cardlist = otherBookmarks.map(x => {
        let groupData = linkageJS.getGroupData(x)
        // 所有属性
        let allproperties = []
        groupData = groupData.map(g => {
          g.child = g.child.map(c => {
            allproperties = allproperties.concat(c.properties)
            c.properties = c.properties.filter(p => p.dataType === this.lastgroup.dataType)
            return c
          })
          return g
        })
        const findset = settingsarr.find(p => p.id == x.id)
        let findsamegroup = ''
        groupData.forEach(g => {
          if (g.child) {
            g.child.forEach(c => {
              const findp = c.properties.find(p => (findset ? p.code == findset.linkageCode : p.code == this.lastgroup.code))
              if (findp) {
                findsamegroup = findp
                findsamegroup.groupCodeName = c.groupCode === 'event' ? '事件' : c.groupCode === 'entity' ? '实体' : ''
                if (g.id) {
                  findsamegroup.groupName = g.groupName
                }
              }
            })
          }
        })
        let filterSameName = []
        if (findset) {
          filterSameName = allproperties.filter(p => p.name == findset.name)
        } else {
          filterSameName = allproperties.filter(p => p.name == this.lastgroup.name)
        }
        let showname = ''
        if (findsamegroup) {
          showname = findsamegroup.groupName ? findsamegroup.groupName + '的' + findsamegroup.name : findsamegroup.name
          const groupCodeName = filterSameName.length > 1 && findsamegroup.groupCodeName
          if (groupCodeName) {
            showname = `${showname}(${groupCodeName})`
          }
        } else {
          showname = '请选择'
        }
        return {
          name: x.name,
          canVisit: x.canVisit,
          id: x.id,
          popVisible: false,
          groupcode: findsamegroup ? findsamegroup.code : '',
          showname: showname,
          groupData,
        }
      })
      this.checkList = settingsarr.map(x => x.id)
    },
    changeGroup(card, group) {
      card.groupcode = group.code
      card.showname = group.showname
      card.eventID = group.eventID
      card.popVisible = false
    },

    initMeta() {
      let beEvents = []
      this.behaviorMeta.forEach(x => {
        beEvents = beEvents.concat(x.events)
      })
      this.allBehaviorMeta = beEvents
    },
    cancel() {
      this.drawer = false
    },
    submit() {
      // 保存后 当前卡片和关联卡片都恢复初始
      const oldlinkageset = _.cloneDeep(this.bookmark.linkageSetting)
      const dashboardID = this.$route.hash.replace('#/', '')
      const linkageBookmarks = this.checkList.map(x => {
        const findcard = this.cardlist.find(c => c.id === x)
        return {
          id: x,
          eventID: findcard.eventID,
          linkageCode: findcard.groupcode,
        }
      })
      const param = {
        bookmarkID: this.bookmark.id,
        dashboardID,
        linkageBookmarks,
      }
      putLinkage(param).then(() => {
        this.$emit('submit', oldlinkageset)
        this.drawer = false
      })
    },
  },
  mounted() {
    this.drawer = true
  },
}
</script>
<style lang="scss" scoped>
::v-deep .linkageDrawer {
  .el-drawer__header {
    @include theme_border_color($theme-border-light);
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding-bottom: 10px;
  }
}
.linkageDrawer {
  .linkagebox {
    height: calc(100% - 50px);
    padding: 0 20px;
    .currentcard {
      margin-bottom: 20px;
      h3 {
        font-weight: normal;
        margin-bottom: 5px;
        @include theme_color2($highcolor-cheng);
      }
      > span {
        @include theme_color2($highcolor-cheng);
      }
    }
    .targetcard {
      height: calc(100% - 80px);
      h3 {
        font-weight: normal;
        margin-bottom: 5px;
        @include theme_color2($highcolor-cheng);
      }
      .list {
        min-height: 200px;
        height: calc(100% - 20px);
        overflow-y: auto;
        @include theme_border_color($theme-border-light);
        padding: 10px 0 10px 10px;
        .el-checkbox-group {
          .el-checkbox {
            display: block;
            line-height: 32px;
            margin-bottom: 5px;
          }
          .checkboxitem {
            display: flex;
            .el-button {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .dialog-footer {
    position: absolute;
    right: 10px;
    bottom: 10px;
    @include theme_border_color($theme-border-light);
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 100%;
    text-align: right;
    padding-top: 10px;
  }
}
</style>
