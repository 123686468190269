// import store from '@/store'
let comJs = {
  getgroupData(_this, param) {
    const state = _this.$store.state
    let groupData = []
    // 不同事件源 数据结构不一样
    // 事件 属性 分群
    if (param.source == 'behavior') {
      const metadata = JSON.parse(JSON.stringify(state.behaviorMeta))
      groupData = behaviorData(param, metadata)
    } else if (param.source == 'entity') {
      const metadata = JSON.parse(JSON.stringify(state.attributeMeta))
      groupData = attributeData(param, metadata)
    } else if (param.source == 'segmentbehavior') {
      const metadata = JSON.parse(JSON.stringify(state.segmentMeta.eventGroups))
      groupData = behaviorData(param, metadata)
    } else if (param.source == 'segmententity') {
      const metadata = JSON.parse(JSON.stringify(state.segmentMeta.categories))
      groupData = attributeData(param, metadata)
    }

    return groupData
  },
}
//事件
function behaviorData(param, metadata) {
  //所有事件 根据已选事件id 得到事件
  let dats = []
  let allEvent = []
  metadata.forEach(x => {
    allEvent = allEvent.concat(x.events)
  })

  const eventIds = param.eventIds || []
  const events = allEvent.filter(x => {
    return eventIds.includes(x.id)
  })

  if (events.length == 0) return dats

  if (events.length === 1) {
    let g = {
      groupName: events[0].name,
      child: events[0].propertyGroups,
    }
    dats.push(g)
  } else {
    let pub1 = events[0].propertyGroups[0].properties
    let pub2 = events[0].propertyGroups[1].properties
    for (let i = 1; i < events.length; i++) {
      pub1 = pub1.filter(item => events[i].propertyGroups[0].properties.some(e => e.code === item.code && e.name === item.name))
      pub2 = pub2.filter(item => events[i].propertyGroups[1].properties.some(e => e.code === item.code && e.name === item.name))
    }
    let g = {
      groupName: '公有属性',
      child: [
        {
          groupCode: 'event',
          groupName: '事件属性',
          properties: pub1,
        },
        {
          groupCode: 'entity',
          groupName: '实体属性',
          properties: pub2,
        },
      ],
    }
    dats.push(g)
    events.forEach(item => {
      let pres = item.propertyGroups
      pres[0].properties = pres[0].properties.filter(item => !pub1.some(e => e.code === item.code))
      pres[1].properties = pres[1].properties.filter(item => !pub2.some(e => e.code === item.code))

      let p = {
        id: item.id,
        groupName: item.name,
        child: pres,
      }
      dats.push(p)
    })
  }

  return dats
}
//属性
function attributeData(param, metadata) {
  let dats = []
  const eventIds = param.eventIds || []
  const events = metadata.filter(x => {
    return eventIds.includes(x.code)
  })
  if (events.length === 1) {
    let g1 = {
      groupName: events[0].name,
      child: [
        {
          groupCode: 'entity',
          properties: events[0].entities,
        },
      ],
    }
    dats.push(g1)
  } else {
    let pub1 = events[0] && events[0].entities
    for (let i = 1; i < events.length; i++) {
      pub1 = pub1.filter(item => events[i].entities.some(e => e.code === item.code && e.name === item.name))
    }
    if (pub1.length > 0) {
      let g2 = {
        groupName: events[0].name,
        child: [
          {
            groupCode: 'entity',
            properties: pub1,
          },
        ],
      }
      dats.push(g2)
    }
    events.forEach(item => {
      let pres = item.entities
      pres = pres.filter(item => !pub1.some(e => e.code === item.code))

      let p = {
        id: item.code,
        groupName: item.name,
        child: [
          {
            groupCode: 'entity',
            properties: pres,
          },
        ],
      }
      dats.push(p)
    })
  }

  return dats
}

export default comJs
