import { updateTimeRange } from '@/api/apiV1'
import { putV2TimeRange } from '@/api/apiV2_dashboard'
import commonJs from '@/script/common.js'
import store from '@/store'

let dashbdjs2 = {
  batchtime(_this, bookmarks, alltime) {
    let version2 = bookmarks.find(x => {
      return x.version == 2.0
    })
    let version1 = bookmarks.find(x => {
      return !x.version
    })
    if (alltime) {
    } else {
      alltime = {
        v2time: [
          {
            start: _this
              .moment()
              .subtract(14, 'days')
              .format('YYYY-MM-DD'),
            end: _this.moment().format('YYYY-MM-DD'),
            mode: 'Absolute',
          },
        ],
      }
    }
    let curtime = alltime.v2time[0]
    let time = ''
    if (curtime.mode == 'Relative') {
      let retime = commonJs.getshowdata(_this, alltime.v2time[0])
      time = retime[0] + ' ~ ' + retime[1]
    } else {
      time = alltime.v2time[0].start + ' ~ ' + alltime.v2time[0].end
    }
    _this
      .$confirm('确定将当前页面所有卡片的时间统一修改为自定义时间(' + time + ')，修改后将无法恢复，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'theme_message',
        type: 'warning',
      })
      .then(async () => {
        _this.$message({
          type: 'success',
          message: '修改成功!',
        })
        if (version1) {
          let alltime_v1 = alltime
          await dashbdjs2.batchV1(_this, alltime, version1)
        }
        if (version2) {
          await dashbdjs2.batchV2(_this, alltime, version2)
        }
        _this.getData()
      })
      .catch(err => {})
  },
  async batchV2(_this, alltime) {
    let timeRanges = alltime.v2time[0]
    let p = {
      dashboardID: _this.$route.hash.replace('#/', ''),
      timeRange: timeRanges,
    }
    await putV2TimeRange(p).then(v => {
      _this.chartdatacache = []
    })
  },
  async batchV1(_this, alltime) {
    let timeRanges = dashbdjs2.buildContrastDate(_this, alltime.v2time[0])
    let param = {
      projectID: store.state.projectID,
    }
    let p = {
      dashboardID: _this.$route.hash.replace('#/', ''),
      timeRanges: timeRanges,
    }
    await updateTimeRange(param, p).then(v => {
      _this.chartdatacache = []
    })
  },
  buildBatchDateV2(time) {
    let result = {}

    return result
  },
  buildContrastDate(_this, timeRange) {
    let result = {
      // nobehavior: JSON.stringify(timeRange),
    }
    let values = []
    if (timeRange.mode == 'Relative') {
      let retime = commonJs.getshowdata(_this, timeRange)
      values = [retime[0], retime[1]]
    } else {
      values = [timeRange.start, timeRange.end]
    }
    let mode = ['StatutoryFestival', 'CustomFestival'].includes(timeRange.mode) ? 'Festival' : timeRange.mode

    let firstdate = {
      dates: values,
      model: mode,
      isCustomDatePeriod: timeRange.mode == 'Relative' && timeRange.relative.defines.length == 2 ? true : false,
      unit: timeRange.mode == 'Relative' ? timeRange.relative.unit : 'Week',
      value: 1,
    }
    result['nobehavior'] = JSON.stringify(firstdate)
    let days = _this.moment(values[1]).diff(_this.moment(values[0]), 'days') + 1
    let months = _this.moment(values[1]).diff(_this.moment(values[0]), 'months') + 1

    let isMonthlyBegin = _this.moment(values[0]).date() == 1
    let isMonthlyEnd = _this.moment(values[1]).date() == _this.moment(values[1]).daysInMonth()
    let interval = isMonthlyBegin && isMonthlyEnd ? { type: 'months', count: months } : { type: 'days', count: days }

    let c = null
    let dates = null
    let contrastTypes = ['nocontrast', 'contrast', 'circle', 'last', 'lastAndCircle']
    for (let i = 0; i < contrastTypes.length; i++) {
      c = contrastTypes[i]
      dates = [
        firstdate,
        { dates: dashbdjs2.getContrastDates(_this, c == 'lastAndCircle' ? 'last' : c, values, interval), model: 'Absolute', unit: 'day', value: 1 },
      ]
      if (c == 'lastAndCircle') {
        dates.push({ dates: dashbdjs2.getContrastDates(_this, 'circle', values, interval), model: 'Absolute', unit: 'day', value: 1 })
      }
      result[c] = JSON.stringify(dates)
    }
    return result
  },
  getContrastDates(_this, contrastType, currentDates, interval) {
    let contrastStart = null
    let contrastEnd = null
    switch (contrastType) {
      case 'nobehavior':
      case 'nocontrast':
      case 'contrast':
      case 'circle':
        contrastStart = _this.moment(currentDates[0]).subtract(interval.type, interval.count)
        break
      case 'last':
        contrastStart = _this.moment(currentDates[0]).subtract(1, 'years')
        break
    }
    let start = contrastStart.format('YYYY-MM-DD')
    contrastEnd = contrastStart.add(interval.type, interval.type == 'months' ? interval.count : interval.count - 1)
    if (interval.type == 'months') {
      contrastEnd = contrastEnd.subtract(1, 'days')
    }
    return [start, contrastEnd.format('YYYY-MM-DD')]
  },
}

export default dashbdjs2
