<template>
  <div v-if="bookmark" class="setChart dis_flex">
    <span
      v-if="
        chartd &&
          chartd.issupportlinkage &&
          bookmark &&
          !['advance', 'template', 'combination'].includes(chartd.searchCode) &&
          bookmark.linkageSetting &&
          bookmark.linkageSetting.bookmarks.length > 0 &&
          $commonJs._point('DS_F0006') &&
          system == 'bi'
      "
      class="setitem"
      :class="{ active: bookmark.linkageSetting.isEnabled }"
      @click="enablelinkage()"
    >
      <el-tooltip effect="dark" content="卡片联动" placement="bottom-start">
        <i class="iconfont iconliandong"></i>
      </el-tooltip>
    </span>
    <span
      v-if="
        chartd.issupportdrill &&
          !['advance', 'template', 'combination'].includes(chartd.searchCode) &&
          bookmark.drillSetting &&
          bookmark.drillSetting.bookmarkIDs.length > 0 &&
          bookmark.drillBookmarks &&
          bookmark.drillBookmarks.length > 0 &&
          $commonJs._point('DS_F0005') &&
          system == 'bi'
      "
      class="setitem setitemdrill"
      :class="{ active: bookmark.drillSetting.isEnabled }"
      @click="enabledrill()"
    >
      <el-tooltip effect="dark" content="卡片钻取" placement="bottom-start">
        <i class="iconfont iconzuanqu1"></i>
      </el-tooltip>
    </span>

    <span v-if="bookmark.linkages && bookmark.linkages.groups.length > 0" class="setitemhover setitem linkagefilter">
      <el-popover popper-class="linkagefilterpopper thmemBottomMenu" placement="top-start" width="200" trigger="hover">
        <div>
          被联动条件：<span
            v-for="(group, index) in bookmark.linkages.groups"
            :key="index"
            v-text="index > 0 && index < bookmark.linkages.groups.length ? '&' + group.name : group.name"
          ></span>
        </div>
        <i class="iconfont iconfunnel" slot="reference"></i>
      </el-popover>
    </span>
    <span class="setitemhover setitem" @click="full(item.i)">
      <i class="iconfont iconquanping"></i>
    </span>
    <div class="setitemhover setitem setbox" @click.stop="clickChartset(item.i)">
      <span>
        <i class="el-icon-s-tools"></i>
      </span>
      <div class="hoverul" v-if="cardsetidx == item.i">
        <ul>
          <li v-if="$commonJs._point('DS_F0003') && system == 'bi'">
            <a v-if="!chartd.lock" :href="chartd.url + '?dashboardID=' + dashboardID" :target="chartd.version == '2.0' ? '' : '_blank'">
              <i class="el-icon-edit"></i>编辑
            </a>
            <a v-else @click="cardlock"><i class="el-icon-edit"></i>编辑</a>
          </li>
          <li @click="searchchange('')"><i class="iconfont iconshuaxin"></i>刷新</li>
          <li
            v-if="
              chartd.issupportlinkage &&
                !['advance', 'template', 'combination'].includes(chartd.searchCode) &&
                ['bar', 'line', 'pie'].includes(chartd.reqObj.mode) &&
                chartd.reqObj.parameter.groups.length > 0 &&
                $commonJs._point('DS_F0006') &&
                system == 'bi'
            "
            @click="setlinkage"
          >
            <i class="iconfont iconliandong"></i>联动
          </li>
          <li
            v-if="
              chartd.issupportdrill && !['advance', 'template', 'combination'].includes(chartd.searchCode) && $commonJs._point('DS_F0005') && system == 'bi'
            "
            @click="setdrill"
          >
            <i class="iconfont iconzuanqu1"></i>钻取
          </li>
          <li class="downitem">
            <i class="iconfont icondaochupng"></i>导出
            <div class="downbox">
              <span @click="downPNG(item.i)"><i class="iconfont icondaochupng"></i>导出 png</span>
              <span v-if="canExport(chartd)" @click="downExcel(item.i)"><i class="iconfont icondaochu"></i>导出 Excel</span>
              <span v-if="chartd.searchCode === 'combination'" @click="downExcel2(item.i)"><i class="iconfont icondaochu"></i>导出 Excel</span>
            </div>
          </li>
          <li v-if="apivisible && !['advance', 'combination'].includes(chartd.searchCode) && system == 'bi'">
            <div @click="copyApiID(item.i)" class="apiid"><i class="iconfont icondaochuapi"></i>复制 API ID</div>
            <el-popover popper-class="wenhao-pop" placement="top-start" title="" trigger="click">
              <div class="popinner">API ID 用于当前卡片定义、数据输出。</div>
              <i class="wenhao iconfont iconwenhao4" slot="reference" @click.stop></i>
            </el-popover>
          </li>

          <li v-if="$commonJs._point('DS_F0003') && system == 'bi'" @click="createCopy"><i class="iconfont iconmobanguanli"></i>创建副本</li>
          <li v-if="$commonJs._point('BI报表_卡片_删除') && system == 'bi'" @click="delbookMark"><i class="iconfont iconshanchu"></i>移除</li>
        </ul>
      </div>
    </div>
    <el-dialog
      class="carddeletedialog thmemBottomMenu"
      title="提示"
      :visible.sync="visible"
      width="600px"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="cancel"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    >
      <div class="dialog-body">
        <el-radio-group v-model="deltype">
          <el-radio label="delete">从当前页面移除，同时删除该卡片</el-radio>
          <el-radio label="remove">仅从当前页面移除</el-radio>
        </el-radio-group>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="cancel">取 消</el-button>
        <el-button type="primary" size="small" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 钻取 联动 -->
    <linkage v-if="linkagekey && bookmark" :bookmark="bookmark" :bookmarks="bookmarks" @submit="linkagesubmit" @closeLinkage="closeLinkage" />
    <drill v-if="drillkey && bookmark" :bookmark="bookmark" @drillSubmit="drillSubmit" @closeDrill="closeDrill" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import { downloadExcel } from '../downExcel'
import BI_config from '@/utils/config'
import drill from './drill.vue'
import linkage from './linkage.vue'
import {
  getApiId,
  getTemplateByCode,
  dashboardExport,
  postBookmark,
  getBookmarks,
  putDrillSwitch,
  putLinkageSwitch,
  postbzlog,
  getdataexportmode,
} from '@/api/apiV2_dashboard'
export default {
  props: ['item', 'bookmark', 'bookmarks', 'chartd', 'chartsetidx'],
  data() {
    return {
      cardsetidx: 0,
      visible: false,
      deltype: 'delete',
      dashboardID: '',
      drillkey: false,
      linkagekey: false,
    }
  },
  components: { linkage, drill },
  computed: {
    ...mapState({
      system: state => state.system,
      basicInfo: state => state.basicInfo,
      dashboards: state => state.dashboards,
    }),
    apivisible: function() {
      return !this.$EnvIsMallcoo || this.basicInfo.authInfo.isInternal || this.basicInfo.authInfo.IsSuperAdmin
    },
  },
  watch: {
    chartsetidx: function() {
      this.cardsetidx = this.chartsetidx
    },
  },
  created() {
    this.cardsetidx = this.chartsetidx
    this.dashboardID = Number(this.$route.hash.replace('#/', ''))
  },
  methods: {
    canExport(chart) {
      if (['bi'].includes(this.system)) {
        return chart.excelsObj && this.$commonJs._point('BI报表_卡片_导出Excel')
      }
      return chart.excelsObj
    },
    enablelinkage(isclose) {
      const param = {
        dashboardID: this.dashboardID,
        bookmarkID: this.bookmark.id,
        isEnabled: isclose ? false : !this.bookmark.linkageSetting.isEnabled,
      }
      let messageval = param.isEnabled ? '开启联动' : '关闭联动'
      let isshowmessage = true
      if (param.isEnabled && this.bookmark.drillSetting && this.bookmark.drillSetting.isEnabled) {
        isshowmessage = false
        this.enabledrill('close')
      }
      putLinkageSwitch(param).then(v => {
        if (isshowmessage) {
          messageval = isclose ? '开启钻取，关闭联动' : messageval
          this.$message({
            message: messageval,
            type: 'success',
          })
        }
        let linkageSetting = this.bookmark.linkageSetting
        linkageSetting.isEnabled = param.isEnabled
        if (!linkageSetting.isEnabled) {
          const oldlinkageset = _.cloneDeep(this.bookmark.linkageSetting)
          this.$emit('linkageSubmit', oldlinkageset)
        }
      })
    },
    enabledrill(isclose) {
      const param = {
        dashboardID: this.dashboardID,
        bookmarkID: this.bookmark.id,
        isEnabled: isclose ? false : !this.bookmark.drillSetting.isEnabled,
      }
      let messageval = param.isEnabled ? '开启钻取' : '关闭钻取'
      let isshowmessage = true
      if (param.isEnabled && this.bookmark.linkageSetting && this.bookmark.linkageSetting.isEnabled) {
        isshowmessage = false
        this.enablelinkage('close')
      }
      putDrillSwitch(param).then(v => {
        if (isshowmessage) {
          messageval = isclose ? '开启联动，关闭钻取' : messageval
          this.$message({
            message: messageval,
            type: 'success',
          })
        }
        let drillBookmarks = this.bookmark.drillBookmarks
        let drillSetting = this.bookmark.drillSetting
        drillSetting.isEnabled = param.isEnabled
        const drilldata = {
          drillSetting,
          drillBookmarks,
        }
        this.$emit('drillSubmit', drilldata)
      })
    },
    full(idx) {
      this.$emit('startFull', idx)
    },
    clickChartset(i) {
      if (this.cardsetidx == i) {
        this.cardsetidx = -1
      } else {
        this.cardsetidx = i
      }
      this.$emit('chartsetidxChange', this.cardsetidx)
    },
    cardlock() {
      this.$alert(`该卡片已被锁定，不允许当前操作~`, '提示', {
        confirmButtonText: '我知道了',
        type: 'warning',
      })
    },
    searchchange(realtime) {
      this.$emit('searchchange', realtime)
    },
    setlinkage() {
      // 联动： 基于卡片中所选的分组条件，筛选当前数据面板中指定卡片的数据
      // 目前支持联动的卡片类型：柱图，线图，饼图，中国地图
      this.linkagekey = true
    },
    setdrill() {
      this.drillkey = true
    },
    closeDrill(item) {
      this.drillkey = item
    },
    drillSubmit(drilldata) {
      this.$emit('drillSubmit', drilldata)
    },
    closeLinkage(item) {
      this.linkagekey = item
    },
    linkagesubmit(oldlinkageset) {
      this.$emit('linkageSubmit', oldlinkageset)
    },
    downPNG(idx) {
      this.$eventBus.$emit('changedownpngidx', idx)
      const _this = this
      const pageYOffset = window.pageYOffset
      const pageXOffset = window.pageXOffset
      const opts = {
        background: '#FFFFFF',
        logging: false,
        scrollY: -pageYOffset,
        scrollX: -pageXOffset,
        useCORS: true,
        scale: 1,
      }
      setTimeout(() => {
        const dm = _this.$parent.$parent.$parent.$refs['gridItemref' + idx][0]
        html2canvas(dm, opts)
        html2canvas(dm, opts).then(canvas => {
          let link = document.createElement('a')
          link.href = canvas.toDataURL('image/png')
          link.setAttribute('download', this.chartd.reqObj.title + '.png')
          link.click()
          this.$eventBus.$emit('changedownpngidx', -1)
        })
      }, 100)
      postbzlog({
        operation: 'export_png',
        collection: 'Dashboard_Export',
        content: {
          dashboardurl: window.location.href,
          bookmarkID: this.chartd.id,
          name: this.chartd.reqObj.title,
        },
      })
    },
    async downExcel(idx) {
      if (this.chartd.option.noData) {
        return
      }
      if (await this.canOfflineExport(this.chartd)) {
        let params = {
          injection: this.chartd.injectsearchparam,
          fileName: this.chartd.reqObj.title,
        }
        await dashboardExport(params)
        msgbox()
      } else {
        let res = await getdataexportmode()
        let dataExportMode = res.data
        this.downLoadExcel(this.chartd, dataExportMode)
        if (dataExportMode == 2 && !this.chartd.option.noData) {
          msgbox()
        }
      }
      postbzlog({
        operation: 'export_excel',
        collection: 'Dashboard_Export',
        content: {
          dashboardurl: window.location.href,
          bookmarkID: this.chartd.id,
          name: this.chartd.reqObj.title,
        },
      })

      let _this = this
      function msgbox() {
        setTimeout(() => {
          const h = _this.$createElement
          _this.$msgbox({
            title: '提示',
            message: h('div', null, [h('div', null, '下载任务创建成功，稍后请在下载中心查看！ '), h('div', null, '(超过Excel行数上限自动导出CSV格式)')]),
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {
              window.open(`${BI_config.bigmanage}${BI_config.manageProxy}/manage/downCenter/${_this.basicInfo.project.id}`)
            },
          })
        }, 500)
      }
    },
    async downExcel2(idx) {
      let hasExport = false
      let hasOfflineExport = false
      let res = await getdataexportmode()
      let dataExportMode = res.data
      this.chartd.reqObj.cardlist.forEach(async (list, i) => {
        if (list.option.noData) {
          return
        }
        hasExport = true
        if (await this.canOfflineExport(list)) {
          hasOfflineExport = true
          let params = {
            injection: list.reqObj.parameter,
            fileName: this.chartd.reqObj.title,
          }
          params.injection.id = list.bookmark.id
          if (params.injection.timeRanges) {
            params.injection.timeRanges = this.chartd.reqObj.parameter.timeRanges
            if (params.injection.contrastType) {
              const contrastType = this.chartd.reqObj.contrastType
              params.injection.contrastType = contrastType === 'nocontrast' ? 'Current' : contrastType
            }
          }

          await dashboardExport(params)
        } else {
          list.combinationidx = i
          this.downLoadExcel(list, dataExportMode)
        }
      })
      if (hasExport) {
        postbzlog({
          operation: 'export_excel',
          collection: 'Dashboard_Export',
          content: {
            dashboardurl: window.location.href,
            bookmarkID: this.chartd.id,
            name: this.chartd.reqObj.title,
          },
        })
      }
      if (hasOfflineExport || dataExportMode == 2) {
        msgbox()
      }
      let _this = this
      function msgbox() {
        setTimeout(() => {
          const h = _this.$createElement
          _this.$msgbox({
            title: '提示',
            message: h('div', null, [h('div', null, '下载任务创建成功，稍后请在下载中心查看！ '), h('div', null, '(超过Excel行数上限自动导出CSV格式)')]),
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {
              window.open(`${BI_config.bigmanage}${BI_config.manageProxy}/manage/downCenter/${_this.basicInfo.project.id}`)
            },
          })
        }, 500)
      }
    },
    async canOfflineExport(item) {
      if (item.option.noData) return false
      if (item.orginResult.actualLength <= 10000) return false
      if (item.searchCode == 'Scatter') return false
      //离线导出仅支持事件和属性分析 其它分析开发中
      //this.analysisType.category == 'template' advance
      if (item.searchCode == 'template') {
        let res = await getTemplateByCode(item.cardinfos.parameter.code)
        if (res.data.canOfflineExport) {
          return true
        }
      }
      return ['behavior', 'attribute'].includes(item.searchCode)
    },
    downLoadExcel(chart, dataExportMode) {
      if (chart.option.noData) {
        return
      }
      const filename = chart.reqObj.title
      if (!chart.version && chart.searchCode == 'funnel') {
        downloadExcel(
          filename,
          chart.settings,
          chart.excelsObj.tbody,
          chart.excelsObj.thead,
          chart.excelsObj.tableObj,
          chart.settings,
          dataExportMode,
          '模板分析导出'
        )
      } else {
        chart.dataExportMode = dataExportMode
        this.$emit('emitDownloadExcel', chart)
      }
    },
    async copyApiID(idx) {
      const id = this.chartd.id
      let res = await getApiId(id)
      let input = document.createElement('input')
      input.value = res.data
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message({
        message: '已复制到剪贴板',
        type: 'success',
      })
    },
    async createCopy() {
      const defparse = JSON.parse(this.bookmark.definition)
      defparse.settings.nameCN = this.bookmark.name + '的副本'
      let definition = {
        parameter: defparse.parameter,
        settings: defparse.settings,
      }
      let param = {
        nameCN: this.bookmark.name + '的副本',
        nameEN: this.bookmark.nameEN,
        content: this.bookmark.content,
        category: this.bookmark.category,
        enableRealtimeRefresh: this.bookmark && this.bookmark.enableRealtimeRefresh,
        definition,
        dashboardIDs: [this.dashboardID],
      }
      if (this.bookmark.actor) {
        param.actor = this.bookmark.actor
      }
      if (this.bookmark.category === 'template') {
        param.referenceID = this.bookmark.referenceID
      }
      this.$confirm(`是否确定创建副本?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        customClass: 'theme_message',
      }).then(async () => {
        let newid = null
        await postBookmark(param).then(v => {
          newid = v.data
          this.$message({
            message: '已成功创建副本到当前页面',
            type: 'success',
          })
        })
        let bookmarksRes = await getBookmarks(this.system, this.dashboardID)
        const newbookmark = bookmarksRes.data.find(x => x.id == newid)
        this.$emit('createCopy', newbookmark)
      })
    },
    getcurdashboard() {
      let alldashboards = []
      this.dashboards.forEach(x => {
        if (x.nodeType == 'Folder') {
          alldashboards = alldashboards.concat(x.children)
        } else {
          alldashboards = alldashboards.concat(x)
        }
      })
      let dashboard = alldashboards.find(x => {
        return x.id == this.dashboardID
      })
      return dashboard
    },
    delbookMark() {
      const dashboard = this.getcurdashboard()
      if (dashboard.isLocked) {
        this.$alert(`该页面已被锁定，不允许当前操作~`, '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          customClass: 'theme_message',
        })
        return
      }
      this.visible = true
    },
    cancel() {
      this.visible = false
      this.deltype = 'delete'
    },
    submit() {
      this.$emit('delbookMark', this.deltype)
    },
  },
  mounted() {
    if (this.bookmark && this.bookmark.enableRealtimeRefresh) {
      const realtimeCacheWindow = this.basicInfo?.project?.realtimeCacheWindow ?? 600
      setInterval(() => {
        this.searchchange('realtime')
      }, realtimeCacheWindow * 1000)
    }
  },
}
</script>

<style lang="scss">
.carddeletedialog {
  .dialog-body {
    .el-radio-group {
      .el-radio {
        display: block;
        padding-left: 30px;
        line-height: 32px;
      }
    }
  }
}
</style>
