<template>
  <el-drawer
    :title="bookmark && bookmark.drillBookmarks && bookmark.drillSetting.bookmarkIDs.length > 0 ? '编辑卡片钻取' : '创建卡片钻取'"
    custom-class="drillDrawer thmemBottomMenu"
    :visible.sync="drawer"
    direction="rtl"
    :wrapperClosable="false"
    :append-to-body="true"
  >
    <div class="drillbox">
      <span class="drill-title">{{ bookmark.name }}</span>
      <div class="card-box">
        <ul class="left-line">
          <li class="left-cricle" v-for="(item, index) in cardlist" :key="item.id">
            <span class="card-index">{{ index + 1 }}</span>
            <span class="line">
              <span v-if="index === 0" class="spot"></span>
            </span>
          </li>
        </ul>
        <ul class="right-list" id="cardidlist">
          <li class="card-right" v-for="(item, index) in cardlist" :key="item.id">
            <span
              class="card-right-name"
              :class="{ carddelete: !item.canVisit || !(item.canVisit && item.definition) }"
              v-text="!item.canVisit || (item.canVisit && item.definition) ? item.name : item.id + '(已删除)'"
            ></span>
            <div class="card-set" v-if="item.canVisit">
              <i @click="refresh(index, item)" class="el-icon-refresh"></i>
              <i @click="edit(item)" class="el-icon-edit"></i>
              <i class="el-icon-close" slot="reference" @click="del(index)"></i>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="cardlist.length < 5" class="add-card-box">
        <div class="left-line">
          <div class="left-cricle">
            <span class="card-index"></span>
            <span v-if="cardlist.length > 0" class="line"> </span>
          </div>
        </div>
        <el-dropdown szie="mini" placement="right" trigger="click" @command="openAdd">
          <el-button type="text" class="card-btn">+ 新建钻取</el-button>
          <el-dropdown-menu class="thmemRightMenu" slot="dropdown">
            <el-dropdown-item icon="el-icon-circle-plus" command="copy">创建上一级卡片副本</el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-plus" command="card">添加其它卡片</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-popover popper-class="popover-treebox" placement="right-start" width="400" v-model="addcardvisible" trigger="click">
          <div class="treebox" @mouseleave="mouseleave">
            <el-input type="text" v-model="cardKeyword" placeholder="请输入关键字" size="small" @input.native="filterCard"></el-input>
            <el-tree
              class="drillcardtree"
              ref="card"
              :data="showTrees"
              node-key="id"
              :props="defaultProps"
              :default-expanded-keys="cardsexpand"
              @node-click="treesClick"
            >
              <div class="custom-tree-slot" slot-scope="{ node, data }">
                <span :class="{ disabled: data.disabled }">
                  {{ node.label }}<b v-if="data.id">({{ data.id }})</b>
                </span>
              </div>
            </el-tree>
          </div>
        </el-popover>
        <span class="card-btn-tips">(最多支持5级)</span>
      </div>
      <span class="drill-tips">注：拖拽钻取卡片可以改变钻取顺序 </span>
    </div>
    <div class="dialog-footer ">
      <el-button class="cancel" size="small" @click="cancel">取 消</el-button>
      <el-button type="primary" size="small" @click="submit">保 存</el-button>
    </div>

    <el-dialog
      class="carddeletedialog thmemBottomMenu"
      title="提示"
      :visible.sync="delVisible"
      width="600px"
      :append-to-body="true"
      @close="canceldel"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    >
      <div class="dialog-body">
        <el-radio-group v-model="deltype">
          <el-radio label="delete">从当前钻取关系中移除，同时删除该卡片</el-radio>
          <el-radio label="remove">仅从当前钻取关系中移除</el-radio>
        </el-radio-group>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="canceldel">取 消</el-button>
        <el-button type="primary" size="small" @click="submitdel">确 定</el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import Sortable from 'sortablejs'
import BI_config from '@/utils/config'
import { putBookmarkDrill, postBookmark, deleteBookmark } from '@/api/apiV2_dashboard'
import { getBookmark } from '@/api/reportAPI.js'
import linkagedrill from '../js/linkagedrill'
export default {
  props: ['bookmark'],
  data() {
    return {
      drawer: false,
      addcardvisible: false,
      cardKeyword: '',
      copyTrees: [], //接口获取tree
      showTrees: [], //显示的tree
      defaultProps: {
        children: 'bookmarks',
        label: 'name',
      },
      cardlist: [],
      cardsexpand: [],
      delVisible: false,
      deltype: 'delete',
      delidx: 0,
      delcards: [], // 删除卡片id
    }
  },
  created() {},
  computed: {
    ...mapState({
      allcards: state => state.allcards,
      projectID: state => state.projectID,
    }),
  },
  watch: {
    drawer: function() {
      this.$emit('closeDrill', this.drawer)
    },
  },
  methods: {
    async init() {
      this.copyTrees = this.allcards.filter(x => !['分群分析', '聚合分析', '模板分析', '卡片组合'].includes(x.name))
      this.copyTrees = this.copyTrees.filter(x => {
        x.bookmarks = x.bookmarks.filter(book => book.id !== this.bookmark.id)
        return x
      })
      this.showTrees = _.cloneDeep(this.copyTrees)
      this.filterCard()
      if (this.bookmark.drillBookmarks) {
        this.bookmark.drillBookmarks.forEach(x => {
          const finddepend = this.bookmark.dependentBookmarks.find(depend => depend.bookmarkID == x.id)
          const list = {
            id: x.id,
            name: x.name || x.nameCN,
            nameEN: x.nameEN,
            category: x.category,
            canVisit: finddepend.canVisit,
            definition: x.definition,
            enableRealtimeRefresh: x.enableRealtimeRefresh,
            content: x.content,
            referenceID: x.referenceID,
          }
          this.cardlist.push(list)
        })
      }
      setTimeout(() => {
        let _this = this
        let list = document.getElementById('cardidlist')
        Sortable.create(list, {
          handle: '.card-right-name',
          animation: 0,
          onEnd: e => {
            let moveitem = JSON.parse(JSON.stringify(_this.cardlist[e.oldIndex]))
            _this.cardlist.splice(e.oldIndex, 1)
            _this.cardlist.splice(e.newIndex, 0, moveitem)
          },
        })
      }, 500)
    },
    cancel() {
      this.drawer = false
    },
    async submit() {
      const dashboardID = Number(this.$route.hash.replace('#/', ''))
      if (this.cardlist.length > 0) {
        const drillBookmarkIDs = this.cardlist.map(x => x.id)
        const param = {
          bookmarkID: this.bookmark.id,
          dashboardID,
          drillBookmarkIDs,
        }
        await putBookmarkDrill(param)
        let drillSetting = this.bookmark.drillSetting
        if (drillSetting) {
          drillSetting.bookmarkIDs = drillBookmarkIDs
        } else {
          drillSetting = {
            bookmarkIDs: drillBookmarkIDs,
            isEnabled: true,
          }
        }
        const drilldata = {
          drillSetting,
          drillBookmarks: this.cardlist,
        }
        this.$emit('drillSubmit', drilldata)
      } else {
        const param = {
          bookmarkID: this.bookmark.id,
          dashboardID,
          drillBookmarkIDs: [],
        }
        await putBookmarkDrill(param)
        this.bookmark.drillSetting = null
        const drilldata = {
          drillSetting: null,
        }
        this.$emit('drillSubmit', drilldata)
      }
      // 如果有 delcards 删除卡片 则在这里统一删除
      if (this.delcards && this.delcards.length > 0) {
        this.delcards.forEach(x => {
          try {
            deleteBookmark(x)
          } catch (e) {}
        })
      }
      this.drawer = false
    },
    refresh(idx, item) {
      this.getBookmarkParam(item.id, idx)
    },
    edit(item) {
      const href = BI_config.analysisUrl + BI_config.analysisProxy + '/' + item.category + '/' + this.projectID + '/' + item.id
      window.open(href, '_blank')
    },
    del(idx) {
      this.delVisible = true
      this.delidx = idx
    },
    canceldel() {
      this.delVisible = false
      this.drawer = true
    },
    submitdel() {
      if (this.deltype === 'delete') {
        this.delcards.push(this.cardlist[this.delidx].id)
      }
      this.cardlist.splice(this.delidx, 1)
      this.delVisible = false
      this.filterCard()
    },
    treesClick(e) {
      if (e.id && !e.disabled) {
        this.addcardvisible = false
        this.getBookmarkParam(e.id)
      }
    },
    async getBookmarkParam(id, idx) {
      const res = await getBookmark(id)
      const q = res.data
      const list = {
        id,
        name: q.nameCN,
        category: q.category,
        definition: q.definition,
        canVisit: true,
      }
      if (!linkagedrill.isCanAddDrill(this.bookmark, this.cardlist, list)) {
        this.$alert(`该卡片未包含之前卡片所有分组，不支持钻取`, '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
        })
        return
      }
      if (idx || idx === 0) {
        this.$set(this.cardlist, idx, list)
      } else {
        this.cardlist.push(list)
      }
      this.filterCard()
    },
    mouseleave() {},
    filterCard() {
      let _this = this
      setTimeout(() => {
        //关键字不为空 则拷贝数据查询 否则等于全部数据
        let tree = JSON.parse(JSON.stringify(_this.copyTrees))
        let checkedIDlist = _this.cardlist.map(x => {
          return x.id
        })
        _this.showTrees = tree.filter(el => {
          el.bookmarks = el.bookmarks.filter(k => {
            if (checkedIDlist.includes(k.id)) {
              _this.$set(k, 'disabled', true)
            } else {
              _this.$set(k, 'disabled', false)
            }
            return (k.name.includes(_this.cardKeyword) || String(k.id).includes(_this.cardKeyword)) && !_this.delcards.includes(k.id)
          })
          return el.bookmarks.length > 0
        })
      }, 100)
    },
    openAdd(command) {
      console.log(command)
      if (command == 'copy') {
        let lastcard = ''
        if (this.cardlist && this.cardlist.length > 0) {
          lastcard = this.cardlist.at(-1)
        } else {
          lastcard = this.bookmark
        }
        const defparse = JSON.parse(lastcard.definition)
        defparse.settings.nameCN = this.bookmark.name + '的副本'
        let definition = {
          parameter: defparse.parameter,
          settings: defparse.settings,
        }
        let param = {
          nameCN: lastcard.name + '的副本',
          nameEN: lastcard.nameEN,
          content: lastcard.content,
          category: lastcard.category,
          enableRealtimeRefresh: lastcard && lastcard.enableRealtimeRefresh,
          definition,
        }
        if (lastcard.actor) {
          param.actor = lastcard.actor
        }
        if (lastcard.category === 'template') {
          param.referenceID = lastcard.referenceID
        }
        postBookmark(param).then(v => {
          this.$message({
            message: '已成功创建副本',
            type: 'success',
          })
          param.id = v.data
          const newparam = { ...param, name: param.nameCN, canVisit: lastcard.canVisit }
          newparam.definition = JSON.stringify(newparam.definition)
          this.cardlist.push(newparam)
        })
      } else {
        this.addcardvisible = true
      }
    },
  },
  mounted() {
    this.drawer = true
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.drillDrawer {
  .drillbox {
    padding: 0 20px;
    .drill-title {
    }
    .card-box {
      width: 100%;
      padding-top: 25px;
      display: flex;
      .left-line {
        flex-shrink: 0;
        width: 40px;
        .left-cricle {
          width: 25px;
          height: 25px;
          margin: 26px 0;
          line-height: 32px;
          border-radius: 50%;
          border: 1px solid #ccc;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .card-index {
            color: #ccc;
          }
          .line {
            display: block;
            width: 1px;
            height: 28px;
            background: #ccc;
            position: absolute;
            top: -28px;
            left: 11px;
            .spot {
              display: block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: #ccc;
              position: absolute;
              top: -4px;
              left: -3px;
            }
          }
        }
      }
      .right-list {
        width: calc(100% - 30px);
        flex: 1;
        .card-right {
          width: 100%;
          display: flex;
          align-items: center;
          margin-left: 10px;
          padding-left: 5px;
          line-height: 32px;
          background: #ebeef5;
          margin: 20px 0;

          .card-right-name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: all-scroll;
            &.carddelete {
              color: #999;
            }
          }
          .card-set {
            i {
              color: #ccc;
              font-weight: normal;
              font-size: 16px;
              margin-right: 8px;
              cursor: pointer;
              @include high_color1($highcolor-cheng);
            }
          }
        }
      }
    }
    .add-card-box {
      background: none;
      display: flex;
      .left-line {
        flex-shrink: 0;
        width: 40px;
      }
      .left-cricle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #ccc;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .line {
          display: block;
          width: 1px;
          height: 28px;
          background: #ccc;
          position: absolute;
          top: -28px;
          left: 11px;
        }
      }
      .card-btn {
        @include high_color1($highcolor-cheng);
        margin-right: 5px;
        cursor: pointer;
        margin-top: -8px;
      }
      .card-btn-tips {
        color: #ccc;
        margin-top: 2px;
      }
    }
    .drill-tips {
      display: block;
      margin-top: 10px;
      color: #ccc;
    }
  }
}
</style>
<style lang="scss">
.popover-treebox {
  @include theme_bg1($theme-light);
  @include theme_border_color($theme-border-light);
  .treebox {
    .cardcount {
      display: inline-block;
      @include high_color1($highcolor-cheng);
      margin-left: 4px;
    }
    .fullcard {
      color: #ccc;
      .cardcount {
        color: #ccc;
      }
    }
  }
}
.drillcardtree {
  padding-top: 10px;
  background: none !important;
  max-height: 320px;
  @include theme_color2($theme-dark);
  overflow-y: auto;
  > .el-tree-node {
    &:focus > .el-tree-node__content {
      background: none;
    }
    > .el-tree-node__content {
      background: none;
      &:hover {
        // @include high_color1($highcolor-cheng);
        background: none;
      }
    }

    [aria-disabled='true'].el-tree-node {
      > .el-tree-node__content {
        @include high_color1($highcolor-cheng);
      }
    }
    .el-tree-node {
      > .el-tree-node__content {
        background: none;
        &:hover {
          @include high_color1($highcolor-cheng);
          background: none;
        }
      }

      .custom-tree-slot {
        span {
          &.disabled {
            @include high_color1($highcolor-cheng);
          }
          b {
            font-weight: normal;
            color: #999;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
