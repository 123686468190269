<template>
  <div class="grid-head clearfix" v-if="dashboards && dashboards.length > 0">
    <extDatetime :proptimeidx="-1" @timechange="getAlltime" class="alltime" />
    <el-button v-if="$commonJs._point('BI报表_仪盘表_批量修改时间') && system == 'bi'" class="batchtime" plain @click="batchChange">批量修改时间</el-button>
    <el-select class="allslot themeSelect" popper-class="thmemBottomMenu" v-model="allslot" @change="allslotsearch" placeholder="时间粒度">
      <el-option v-for="item in slotoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    </el-select>
    <div class="sences">
      <el-dropdown trigger="click" @command="changemobile" v-if="system == 'bi'">
        <span> <i class="iconfont iconpcduan"></i><span>PC端</span><i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu class="layoutdropdown" slot="dropdown">
          <el-dropdown-item :class="{ active: layouttype === 'pc' }" command="pc"><i class="iconfont iconpcduan"></i><span>PC端</span></el-dropdown-item>
          <el-dropdown-item :class="{ active: layouttype === 'mobile' }" command="mobile"
            ><i class="iconfont iconshoujiduan"></i><span>移动端</span></el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-tooltip content="筛选查询" placement="bottom">
        <div @click="injectionVisible = true" class="dis_flex">
          <i class="iconfont iconfunnel"></i
          ><span v-if="prefilter && prefilter.conditions && prefilter.conditions.length > 0">已选{{ prefilter.conditions.length }}项</span>
        </div>
      </el-tooltip>
      <div v-if="$commonJs._point('BI_F0038') && system == 'bi'" class="subscrip">
        <el-tooltip content="数据订阅" placement="bottom">
          <i class="iconfont icondingyue" :class="{ themeColor1: msgConfig && msgConfig.isEnable }" @click="checkSubscrip"></i>
        </el-tooltip>
        <el-drawer
          custom-class="thmemBottomMenu"
          title="编辑订阅计划"
          :visible.sync="plandrawer"
          direction="rtl"
          :modal-append-to-body="true"
          :size="550"
          :wrapperClosable="false"
          @close="drawerclose"
        >
          <subscribePlan
            v-if="plandrawer"
            ref="planref"
            class="drawer-main"
            :dashboardinfo="dashboard"
            :currow="msgConfig"
            :projectMalls="projectMalls"
            :msginfo="msginfo"
          />
          <div class="footbtns dialog-footer">
            <el-button class="cancel" size="small" @click="plandrawer = false">取 消</el-button>
            <el-button type="primary" size="small" @click="save">保 存</el-button>
          </div>
        </el-drawer>
      </div>
    </div>
    <el-dialog class="dialogFilter thmemBottomMenu" :visible.sync="injectionVisible" :append-to-body="true" :close-on-click-modal="false">
      <div slot="title" class="dialogFilterTitle">
        <h3>筛选查询</h3>
        <el-popover popper-class="wenhao-pop" placement="right-start" title="" width="450" trigger="click">
          <div class="popinner">
            备注：<br />
            1）目前仅支持实体属性的筛选查询，每次最多添加5个条件项；<br />
            2）筛选条件项之间支持“且”、“或”自由切换；<br />
            3）数据面板中的“筛选查询”条件与具体卡片中的“筛选条件”关系为“且”。
          </div>
          <i class="wenhao iconfont iconwenhao4" slot="reference"></i>
        </el-popover>
      </div>
      <div class="dialog-body">
        <headfilter v-if="attrMetadata" ref="filterref" class="filtercontent" :paramfilter="getparamfilter()" @filterchange="filterchange" />
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="injectionVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="confirmInjection">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import headfilter from '@/components2/headfilter.vue'

import BI_config from '@/utils/config'
import { getBehaviorMetadata, getAttrMetadata, getFilterOperator } from '@/api/apiV2_dashboard'
import { getDashboardSubscrip, postSubscription, putSubscription } from '@/api/subscrip.js'

export default {
  props: ['dashboard'],
  components: { headfilter },
  data() {
    return {
      alltime: '',
      allslot: '',
      slotoptions: [
        { value: 'hour', label: '按小时' },
        { value: 'day', label: '按天' },
        { value: 'week', label: '按周' },
        { value: 'month', label: '按月' },
        { value: 'quarter', label: '按季度' },
      ],

      copyallMalls: [],
      mallFilter: '',
      popperVisible: false,
      popoverkey: 0,
      curScene: null,
      allCardkey: 0,

      timer: 0,
      injectionVisible: false,

      attrMetadata: null,
      prefilter: {},

      plandrawer: false,
      currow: '',
      msgConfig: null,

      layouttype: 'pc',
      msginfo: {},
    }
  },
  computed: {
    ...mapState({
      projectID: state => state.projectID,
      system: state => state.system,
      dashboards: state => state.dashboards,
      basicInfo: state => state.basicInfo,
      projectMalls: state => state.projectMalls,
    }),
  },
  created() {
    this.getSubscripe()
    this.msginfo = {
      emailoptions: {
        createtpllink: `${BI_config.mpUrl}/a/email/tpllist`,
      },
    }
  },
  methods: {
    // 批量查询 点击修改时 先循环一下书签 确定是否满足条件 然后修改查询条件 清空当前数据 请求接口赋值数据
    getAlltime(item) {
      this.$emit('changealltime', item)
    },
    allslotsearch(item) {
      this.$parent.allslotSearch(item)
    },
    batchChange() {
      this.$parent.batchChange()
    },

    hidepopper() {
      this.popoverkey++
    },
    confirmInjection() {
      this.prefilter = this.$refs['filterref'].exportfilter()
      this.injectionVisible = false
      this.$emit('confirmInjection', this.prefilter)
    },
    filterchange(item) {
      this.filterlist = item
    },
    getparamfilter() {
      let param = {}
      param.filter = null
      param.source = 'entity'
      param.eventIds = this.attrMetadata.map(x => x.code)
      return param
    },
    changemobile(item) {
      console.log(item)
      this.layouttype = item
      if (item === 'mobile') {
        this.$router.push({ path: '/v2/dashboard/Index/mobile/' + this.$route.params.projectID + this.$route.hash })
      }
    },

    drawerclose() {},
    async getSubscripe() {
      const dashboardID = this.$route.query.dashboardID || this.$route.hash.replace('#/', '')
      const res = await getDashboardSubscrip(dashboardID)
      this.msgConfig = res.data
    },
    checkSubscrip() {
      this.plandrawer = true
    },
    save() {
      const params = this.$refs['planref'].exportParam()
      if (params) {
        if (params.planID) {
          putSubscription(params).then(v => {
            this.$message({
              message: '更新成功',
              type: 'success',
            })
            this.plandrawer = false
            this.msgConfig.isEnable = params.isEnable
          })
        } else {
          postSubscription(params).then(v => {
            this.$message({
              message: '创建成功',
              type: 'success',
            })
            this.plandrawer = false
            this.msgConfig.isEnable = params.isEnable
          })
        }
      }
    },
  },
  mounted() {
    getBehaviorMetadata().then(v => {
      this.$store.commit('setBehaviorMeta', v.data)
    })
    getAttrMetadata().then(v => {
      this.$store.commit('setAttributeMeta', v.data)
      this.attrMetadata = v.data
    })
    getFilterOperator().then(v => {
      this.$store.commit('setOperators', v.data)
    })
  },
}
</script>

<style lang="scss" scoped>
.grid-head {
  text-align: left;
  margin: 0 15px;
  border-radius: 6px;
  padding: 10px 15px;
  @include theme_bg1($theme-light);
  .alltime {
    float: left;
    min-width: 250px;
    margin-right: 10px;
    .all-time-btn {
      @include theme_color1($theme-light);
    }
  }
  .batchtime {
    float: left;
    width: 124px;
    margin-right: 10px;
    @include theme_color2($theme-light);
  }
  .allslot {
    float: left;
    width: 110px;
    margin-right: 10px;
    @include theme_color2($theme-light);
  }
  .sences {
    display: flex;
    align-items: center;
    cursor: pointer;
    float: right;
    margin-top: 10px;
    @include theme_color2($theme-light);
    .el-dropdown {
      margin-right: 5px;

      .iconpcduan {
        font-size: 16px;
        margin-right: 5px;
      }
      > span {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          @include high_color1($highcolor-cheng);
        }
      }
    }
    .subscrip {
      margin-left: 10px;
      .footbtns {
        .el-button {
          width: 80px;
          border-radius: 20px;
        }
      }
      > i {
        font-size: 18px;
      }
    }
    .el-tooltip {
      span {
        @include high_color1($highcolor-cheng);
        font-size: 12px;
      }
    }
    i {
      &:hover {
        @include high_color1($highcolor-cheng);
      }
    }
  }
}
</style>

<style lang="scss">
.layoutdropdown {
  .el-dropdown-menu__item {
    display: flex;
  }
  .el-dropdown-menu__item:hover,
  .active {
    @include high_color1($highcolor-cheng);
  }

  i {
    font-size: 12px;
    width: 16px;
    text-align: center;
    &.iconpcduan {
      font-size: 16px;
    }
  }
}
.sences {
  .iconfont {
    font-size: 20px;
  }
}
.el-popover.sences-popover {
  .mallFilter {
    width: 100%;
    margin-bottom: 10px;
  }
  .foot-btns {
    margin-top: 10px;
    text-align: right;
  }
}
.dialogFilter {
  .el-dialog {
    width: fit-content;
    min-width: 600px;
    .dialogFilterTitle {
      h3 {
        display: inline-block;
        font-weight: normal;
        margin-right: 5px;
        font-size: 16px;
        @include theme_color2($highcolor-cheng);
      }
    }
    .filtercontent {
      margin-left: 40px;
    }
  }
}
</style>
