<template>
  <div class="groupDilog">
    <div class="search">
      <el-input v-model="searchWords" placeholder="请输入内容" @input="filtKeyup"></el-input>
    </div>
    <div class="fields" :key="listkey">
      <div class="groupitem" v-for="(item, index) in showgroup" :key="index">
        <template
          v-if="
            (item.child[0].properties && item.child[0].properties.length > 0) ||
              (item.child[1] && item.child[1].properties && item.child[1].properties.length > 0)
          "
        >
          <div class="tit">{{ item.groupName }}</div>
          <div class="group" v-for="(g, gidx) in item.child" v-show="g.properties.length" :key="gidx">
            <div class="label" v-if="g.groupName">{{ g.groupName }}：</div>
            <ul v-if="g.properties && g.properties.length > 0">
              <li v-for="(n, nidx) in g.properties" @click="choicename(index, item, n)" :key="nidx" :class="{ selected: n.selected }">
                <span>{{ n.name }}</span>
              </li>
            </ul>
          </div>
        </template>
        <template v-if="item.child.length > 0 && !item.child[0].properties">
          <div class="tit">{{ item.groupName }}</div>
          <div class="group">
            <ul>
              <li v-for="(g, gidx) in item.child" @click="choicename(index, item, g)" :key="gidx" :class="{ selected: g.selected }">
                <span>{{ g.name }}</span>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'groupPopper',
  props: ['groupdata'],
  data() {
    return {
      searchWords: '',
      showgroup: null,
      savegroupdata: null,
      copygroup: null,
      listkey: 0,
    }
  },
  created() {
    this.showgroup = JSON.parse(JSON.stringify(this.groupdata))
    this.savegroupdata = JSON.parse(JSON.stringify(this.groupdata))
  },
  methods: {
    choicename(idx, item, group) {
      if (item.id) {
        group.eventID = item.id
        group.showname = `${item.groupName}的${group.name}`
      } else {
        group.showname = `${group.name}`
      }
      // 所有属性
      let allproperties = []
      this.savegroupdata.forEach(g => {
        g.child.forEach(c => {
          allproperties = allproperties.concat(c.properties)
          const findgroup = c.properties.find(p => p.code === group.code)
          if (findgroup) {
            group.groupCodeName = c.groupCode === 'event' ? '事件' : c.groupCode === 'entity' ? '实体' : ''
          }
        })
      })
      let filterSameName = []
      filterSameName = allproperties.filter(p => p.name == group.name)
      if (filterSameName.length > 1) {
        group.showname = `${group.showname}(${group.groupCodeName})`
      }

      this.$emit('change-group', group)
    },
    filtKeyup() {
      this.copygroup = JSON.parse(JSON.stringify(this.groupdata))
      if (this.searchWords) {
        this.showgroup = this.copygroup.map(x => {
          if (x.child[0].properties) {
            x.child = x.child.map(k => {
              k.properties = k.properties.filter(p => {
                return this.$commonJs2.matchSoftly(p.name, this.searchWords)
              })
              return k
            })
          } else {
            x.child = x.child.filter(k => {
              return this.$commonJs2.matchSoftly(k.name, this.searchWords)
            })
          }

          return x
        })
        this.listkey++
      } else {
        this.showgroup = this.savegroupdata
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.groupDilog {
  font-size: 14px;
  .fields {
    max-height: 500px;
    overflow-y: scroll;
    margin-top: 10px;
    @include theme_bg1($theme-light);
    .groupitem {
      margin-bottom: 10px;
    }
    .tit {
      line-height: 32px;
      font-size: 14px;
      @include theme_color2($highcolor-cheng);
      @include theme_border_color($theme-border-light);
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      font-weight: bold;
    }
    .group {
      display: flex;
      margin-top: 10px;
      .label {
        line-height: 34px;
      }
      ul {
        flex: 1;
        overflow: hidden;
        li {
          width: calc(33% - 10px);
          float: left;
          height: 34px;
          line-height: 34px;
          padding: 0 10px 0 5px;
          margin: 5px;
          margin-top: 0;
          cursor: pointer;
          @include theme_color2($highcolor-cheng);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover {
            @include high_color1($highcolor-cheng);
          }
          &.selected {
            color: #999;
            background: #f5f7fa;
            cursor: not-allowed;
          }
          > span {
            display: inline-block;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
