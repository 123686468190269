<template>
  <div class="gridBar">
    <div class="gridtitle">
      {{ chartd.reqObj.title || chartd.reqObj[0].displayTitle }}
      <el-popover v-if="chartd.reqObj.content" popper-class="wenhao-pop" placement="bottom-start" title="" trigger="click">
        <div class="popinner">{{ chartd.reqObj.content }}</div>
        <i class="wenhao iconfont iconwenhao4" slot="reference"></i>
      </el-popover>
    </div>
    <div class="gridoption clearfix" v-if="chartd.canVisit">
      <div
        v-if="chartd.timeRange && downpngidx !== item.i"
        class="set-time"
        :style="{
          'max-width': chartd.timeRange[1] && (chartd.reqObj.contrastType == 'contrast' || chartd.searchCode == 'contrast') ? '530px' : '370px',
        }"
      >
        <div class="timebox dis_flex" v-if="chartd.timeRange">
          <extDatetime
            :key="JSON.stringify(chartd.timeRange[0]) + '01' + item.i"
            :proptimeidx="0"
            :propitemidx="item.i"
            :maxInterval="getTimePickerInterval(chartd, 0)"
            :propDate="chartd.timeRange"
            :isdisabled="prefilter && prefilter.length > 0"
            @timechange="time => gettimeRange(1, time)"
            class="lefttime"
          />
          <el-select
            class="set-contrast"
            popper-class="thmemBottomMenu"
            v-if="chartd.reqObj.contrastType && !chartd.reqObj.top"
            v-model="chartd.reqObj.contrastType"
            size="mini"
            :disabled="prefilter && prefilter.length > 0"
            @change="searchchange(chartd.reqObj.contrastType, 'contrast')"
          >
            <el-option v-for="item in contrastoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-popover
            v-if="chartd.reqObj.contrastType && !chartd.reqObj.top && chartd.reqObj.contrastType !== 'nocontrast'"
            popper-class="wenhao-pop"
            placement="bottom-start"
            title=""
            width="400"
            trigger="click"
          >
            <ul>
              <li><b>环比：</b></li>
              <li>指选择时间段与上一周期时间段的数据对比。</li>
              <li>举例，“2020-10-01 至 2020-10-07” 的环比时间为</li>
              <li>2020-09-24 至 2020-09-30”；</li>
              <li><b>同比：</b></li>
              <li>指选择时间段的去年同期时间段的数据对比。</li>
              <li>举例，“2020-10-01 至 2020-10-07” 的同比时间为</li>
              <li>“2019-10-01 至 2019-10-07”；</li>
              <li class="mini">
                <b>特别说明：</b>
                <p>若分组方式中设置了“事件发生时间”的分组粒度大于用户所选时间范围，则系统自动按分组的时间粒度计算数据。</p>
                <p>举例，时间控件选择的时间为“2020-10-01 至 2020-10-07” ，分析维度中“事件发生时间”按“月”分组。则：</p>
                <p>1）基准数据为“2020-10-01 至 2020-10-31”的数据；</p>
                <p>2）环比数据为“2020-09-01 至 2020-09-30”的数据；</p>
                <p>3）同比数据为“2019-10-01 至 2019-10-31”的数据。</p>
              </li>
            </ul>
            <i class="wenhao iconfont iconwenhao4" slot="reference"></i>
          </el-popover>
          <span class="contrastspan" v-if="chartd.searchCode == 'contrast'">对比</span>
          <extDatetime
            v-if="chartd.timeRange[1] && (chartd.reqObj.contrastType == 'contrast' || chartd.searchCode == 'contrast')"
            :key="JSON.stringify(chartd.timeRange[1]) + '11' + item.i"
            :proptimeidx="1"
            :propitemidx="item.i"
            :maxInterval="getTimePickerInterval(chartd, 1)"
            :propDate="chartd.timeRange"
            :isdisabled="prefilter && prefilter.length > 0"
            @timechange="time => gettimeRange(2, time)"
            class="righttime"
          />
          <div
            class="set-slot"
            v-if="
              chartd.otherset.slot &&
                slotshowarr.includes(chartd.otherset.slot) &&
                !chartd.reqObj.top &&
                !(bookmark && bookmark.drillSetting && bookmark.drillSetting.bookmarkIDs.length > 0)
            "
          >
            <el-select
              v-model="chartd.otherset.slot"
              popper-class="thmemBottomMenu"
              size="mini"
              :disabled="prefilter && prefilter.length > 0"
              @change="searchchange(chartd.otherset.slot, 'slot')"
            >
              <el-option v-for="item in slotoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="!chartd.timeRange" class="nodate"></div>

      <!-- 导出png图片时 显示内容不一样 -->
      <div
        v-if="chartd.timeRange && downpngidx === item.i"
        class="set-time downpng"
        :style="{
          'max-width': chartd.timeRange[1] && (chartd.reqObj.contrastType == 'contrast' || chartd.searchCode == 'contrast') ? '505px' : '350px',
        }"
      >
        <div class="timebox dis_flex" v-if="chartd.timeRange">
          <showDatetime
            :key="JSON.stringify(chartd.timeRange[0]) + '01' + item.i"
            :proptimeidx="0"
            :propitemidx="item.i"
            :propDate="chartd.timeRange"
            class="lefttime"
          />
          <el-select
            v-if="chartd.reqObj.contrastType && !chartd.reqObj.top && chartd.reqObj.contrastType !== 'nocontrast'"
            popper-class="thmemBottomMenu"
            v-model="chartd.reqObj.contrastType"
            size="mini"
            @change="searchchange(chartd.reqObj.contrastType, 'contrast')"
            class="set-contrast"
          >
            <el-option v-for="item in contrastoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <span class="contrastspan" v-if="chartd.searchCode == 'contrast'">对比</span>
          <showDatetime
            v-if="chartd.timeRange[1] && (chartd.reqObj.contrastType == 'contrast' || chartd.searchCode == 'contrast')"
            :key="JSON.stringify(chartd.timeRange[1]) + '11' + item.i"
            :proptimeidx="1"
            :propitemidx="item.i"
            :propDate="chartd.timeRange"
            class="righttime"
          />
          <div
            class="set-slot"
            v-if="chartd.otherset.slot && slotshowarr.includes(chartd.otherset.slot) && !chartd.reqObj.top"
            :class="{ alignleft: !chartd.timeRange[1] && chartd.reqObj.contrastType != 'nocontrast' && chartd.searchCode !== 'nocontrast' }"
          >
            <el-select v-model="chartd.otherset.slot" popper-class="thmemBottomMenu" size="mini" @change="searchchange(chartd.otherset.slot, 'slot')">
              <el-option v-for="item in slotoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bookmark', 'item', 'chartd', 'chartsetidx', 'prefilter'],
  data() {
    return {
      downpngidx: -1,
      contrastoptions: [
        { value: 'nocontrast', label: '无对比' },
        { value: 'contrast', label: '对比' },
        { value: 'last', label: '同比' },
        { value: 'circle', label: '环比' },
        { value: 'lastandcircle', label: '同环比' },
      ],
      allslot: 'day',
      slotoptions: [
        { value: 'hour', label: '按小时' },
        { value: 'day', label: '按天' },
        { value: 'week', label: '按周' },
        { value: 'month', label: '按月' },
        { value: 'quarter', label: '按季度' },
      ],
      slotshowarr: ['hour', 'day', 'week', 'month', 'quarter'],
    }
  },
  created() {},
  methods: {
    getTimePickerInterval(chart, index) {
      if (!chart.timePickerIntervals) {
        return -1
      }
      let arrs = [...chart.timePickerIntervals]
      arrs.push(-1)
      arrs.push(-1)
      return arrs[index]
    },
    gettimeRange(timeidx, time) {
      this.$emit('timechange', { timeidx: timeidx, time: time })
    },
    searchchange(contrast, type) {
      this.$emit('searchchange', { contrast: contrast, type: type })
    },
  },
  mounted() {
    this.$eventBus.$on('changedownpngidx', val => {
      this.downpngidx = val
    })
  },
  destroyed() {
    this.$eventBus.$off('changedownpngidx')
  },
}
</script>

<style lang="scss" scoped>
.gridBar {
  .gridoption {
    .downpng {
      .timebox {
        .set-slot.alignleft {
          .el-select ::v-deep .el-input input {
            text-align: left;
          }
        }
      }
    }
    .nodate {
      width: 100%;
      height: 8px;
    }
  }
}
</style>
