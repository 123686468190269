import commonJs from '@/script/common.js'
export function downloadExcel(filename, opts, tableData, col, tableObj, setting = {}, dataExportMode = 1, taskType) {
  let pivot = opts.styleSet.enablePivot
  let { propSet } = setting
  const propSetprops = propSet?.props ?? []
  //全部转换 合并表头
  if (pivot == 'full') {
    let resprop = fullhd(opts, col, tableObj)
    let multihd = resprop.multihds
    let merges = fullhdmerges(multihd, opts, tableObj)
    import('@/vendors/Export2Excel').then(excel => {
      const multiHeader = multihd
      const filterVal = fullprop(col)
      const header = resprop.hds
      const data = formatFullJson(filterVal, tableData, tableObj, propSetprops)
      excel.export_json_to_excel({
        filename,
        multiHeader,
        header,
        data,
        merges,
        dataExportMode,
        taskType,
      })
    })
    //部分转换
  } else if (pivot == 'part') {
    let respartprop = parthd(opts, col, tableObj)
    const multiparthd = respartprop.multihds
    const header = respartprop.hds

    let merges = parthdmerges(multiparthd, header, opts)
    import('@/vendors/Export2Excel').then(excel => {
      const multiHeader = multiparthd
      const filterVal = fullprop(col)
      const data = formatPartJson(filterVal, tableData, tableObj, propSetprops)
      excel.export_json_to_excel({
        filename,
        multiHeader,
        header,
        data,
        merges,
        dataExportMode,
        taskType,
      })
    })
    //关闭情况 只有一个表头 合并左侧分组
  } else {
    let hd = col.map(x => {
      return x.label
    })
    let hdgroup = col.filter(x => {
      return x.isGroup
    })

    import('@/vendors/Export2Excel').then(excel => {
      const multiHeader = []
      const header = hd
      const data = formatClosedJson(col, tableData, propSetprops)
      const merges = opts.styleSet.enableConflation ? getClosedNewData(data, hdgroup) : []
      excel.export_json_to_excel({
        filename,
        multiHeader,
        header,
        data,
        merges,
        dataExportMode,
        taskType,
      })
    })
  }
}

//full
function fullhd(opts, col, tableObj) {
  let multihds = []
  let hds = []

  let allDimValues = tableObj.params.allDimValues
  let groups = tableObj.params.groups
  let allDim = allDimValues.map(x => {
    return x.split('[|]')
  })
  //如果有汇总指标
  if (opts.styleSet.aggregation && opts.styleSet.aggregation != 'none') {
    let aggreg = ''
    switch (opts.styleSet.aggregation) {
      case 'sum':
        aggreg = '总计'
        break
      case 'average':
        aggreg = '平均值'
        break
      case 'averageExt':
        aggreg = '去零平均值'
        break
    }
    let aggreglist = []
    allDim[0].forEach(x => {
      aggreglist.push(aggreg)
    })
    allDim.unshift(aggreglist)
  }
  groups.forEach((x, gi) => {
    if (gi == groups.length - 1) {
      let dimlist1 = allDim.map(ad => {
        return ad[gi]
      })
      dimlist1.unshift(x.title)
      hds = dimlist1
    } else {
      let dimlist = allDim.map(ad => {
        return ad[gi]
      })
      dimlist.unshift(x.title)
      multihds.push(dimlist)
    }
  })
  return { multihds, hds }
}
function fullhdmerges(multihd, opts, tableObj) {
  let merg = []
  if (opts.styleSet.aggregation != 'none') {
    let m1 = {
      s: { c: 1, r: 0 },
      e: { c: 1, r: tableObj.params.groups.length - 1 },
    }
    merg.push(m1)
  }
  if (opts.styleSet.enableConflation) {
    multihd.forEach((x, xi) => {
      let m = getMerges(xi, x, 0)
      merg = merg.concat(m)
    })
  }
  return merg
}
function fullprop(col) {
  let prop = []
  col.forEach((c, ci) => {
    self_fun(c, ci)
  })
  function self_fun(c, ci) {
    if (c.children) {
      c.children.forEach((c, cii) => {
        self_fun(c, cii)
      })
    } else {
      prop.push(c.prop)
    }
  }
  return prop
}
function formatFullJson(filterVal, jsonData, tableObj, propSetprops) {
  return jsonData.map(v =>
    filterVal.map(j => {
      let curprop = tableObj.params.prop.find(x => {
        return x.alias == v.prop
      })
      const prp = (curprop && propSetprops.find(p => p.alias === curprop.alias)) || null
      const fmt = prp?.format ?? null
      return commonJs.formatDisplay2(v[j], curprop && curprop.displayType, fmt)
    })
  )
}

//part
function parthd(opts, col, tableObj) {
  let multihds = []
  let hds = []

  let otherDimValues = tableObj.params.otherDimValues

  let partprops = opts.styleSet.partprops || ['g0']
  let groups = tableObj.params.groups.filter(x => !partprops.includes(x.alias))
  let partgroups = tableObj.params.groups.filter(x => partprops.includes(x.alias))
  groups.push('')
  let prop = tableObj.params.prop
  let otherDim = otherDimValues.map(x => {
    return x.split('[|]')
  })
  //如果是占比 事件后添加占比
  let newprop = []
  if (opts.styleSet.enableOccupation) {
    prop.forEach((x, i) => {
      newprop = [...newprop, x]
      if (x.usage === 'Measure') {
        const occ = {
          title: '占比',
        }
        newprop = [...newprop, occ]
      }
    })
  } else {
    newprop = prop
  }
  let newDim = []
  newprop.forEach(x => {
    otherDim.forEach(od => {
      let copyod = JSON.parse(JSON.stringify(od))
      copyod.unshift(x.title)
      newDim.push(copyod)
    })
  })

  groups.forEach((x, gi) => {
    if (gi == groups.length - 1) {
      let dimlist1 = newDim.map(ad => {
        return ad[gi]
      })
      partgroups.reverse().forEach(pg => {
        dimlist1.unshift(pg.title)
      })
      hds = dimlist1
    } else {
      let dimlist = newDim.map(ad => {
        return ad[gi]
      })
      partgroups.reverse().forEach(pg => {
        dimlist.unshift(pg.title)
      })
      multihds.push(dimlist)
    }
  })
  return { multihds, hds }
}
function parthdmerges(multiparthd, header, opts) {
  let merg = []
  if (opts.styleSet.partprops) {
    opts.styleSet.partprops.forEach((x, xidx) => {
      let m1 = {
        s: { c: xidx, r: 0 },
        e: { c: xidx, r: multiparthd.length },
      }
      merg.push(m1)
    })
  }
  if (opts.styleSet.enableConflation) {
    multiparthd.forEach((x, xi) => {
      let m = getMerges(xi, x, 0)
      merg = merg.concat(m)
    })
  }
  return merg
}
function formatPartJson(filterVal, jsonData, tableObj, propSetprops) {
  return jsonData.map(v =>
    filterVal.map(j => {
      let curprop = tableObj.params.prop.find(x => {
        return j.split('[&]').includes(x.alias) || j.split('[&]').includes(x.alias + '_orig')
      })
      const prp = (curprop && propSetprops.find(p => p.alias === curprop.alias)) || null
      const fmt = prp?.format ?? null
      return commonJs.formatDisplay2(v[j], curprop && curprop.displayType, fmt)
    })
  )
}

//closed
function getClosedNewData(data, hdgroup) {
  let list = []

  hdgroup.forEach((c, ci) => {
    let curd = data.map(d => {
      return d[ci]
    })
    let islast = ci < hdgroup.length - 1
    list = list.concat(getCloseMerge(ci, curd, 1, islast))
  })
  return list
}
function getCloseMerge(ci, data, groupLen, islast) {
  let result = []
  let current = []
  let flag = true
  const len = data.length
  for (let i = 0; i < len - 1; i++) {
    if (data[i] == data[i + 1] && flag && islast) {
      current[0] = i
      flag = false
    } else if (data[i] != data[i + 1] && !flag) {
      current[1] = i
      const merge = {
        s: { c: ci, r: current[0] + groupLen },
        e: { c: ci, r: current[1] + groupLen },
      }
      result.push(merge)
      current = []
      flag = true
    }
    if (i == len - 2 && data[i] == data[i + 1] && !flag) {
      current[1] = i + 1
      const merge = {
        s: { c: ci, r: current[0] + groupLen },
        e: { c: ci, r: current[1] + groupLen },
      }
      result.push(merge)
    }
  }
  return result
}
function getMerges(ci, data, groupLen) {
  let result = []
  let current = []
  let flag = true
  const len = data.length
  for (let i = 0; i < len - 1; i++) {
    if (data[i] == data[i + 1] && flag) {
      current[0] = i
      flag = false
    } else if (data[i] != data[i + 1] && !flag) {
      current[1] = i
      const merge = {
        s: { c: current[0] + groupLen, r: ci },
        e: { c: current[1] + groupLen, r: ci },
      }
      result.push(merge)
      current = []
      flag = true
    }
    if (i == len - 2 && data[i] == data[i + 1] && !flag) {
      current[1] = i + 1
      const merge = {
        s: { c: current[0] + groupLen, r: ci },
        e: { c: current[1] + groupLen, r: ci },
      }
      result.push(merge)
    }
  }
  return result
}
function formatClosedJson(col, jsonData, propSetprops) {
  return jsonData.map(v =>
    col.map(j => {
      const prp = propSetprops.find(p => p.alias === j.prop || p.alias + '_orig' === j.prop)
      const fmt = prp?.format ?? null
      // const num = commonJs.formatDisplay2(v[j.prop], j.displayType, fmt)
      const num = v[j.prop]
      if (num !== '-' && typeof num === 'number' && !isNaN(Number(num))) {
        return Number(num)
      } else {
        return num
      }
    })
  )
}
